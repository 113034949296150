import axios from "axios";
import { graylogApi } from "src/config-url";

const logToGraylog = async ({ message, logLevel = 2 }) => {
  const accessToken = localStorage.getItem("accessToken");
  const backEndFeaturesString = localStorage.getItem("backEndFeatures");
  const remoteLogger = backEndFeaturesString ? JSON.parse(backEndFeaturesString)?.remoteLogger : false;
  if (!accessToken || !remoteLogger) {
    return;
  }

  // use axios because possible infinity loop in axios instance
  try {
    await axios.post(
      graylogApi,
      {
        message,
        logLevel,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  } catch (error) {
    console.error("Error logging to Graylog:", error);
  }
};

export default logToGraylog;
