import { configureStore } from "@reduxjs/toolkit";

import { rtkApi } from "src/shared/api/rtkApi";

import errorHandlerMiddleware from "./middlewares/errorMiddleware";
import rootReducer from "./reducer";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(rtkApi.middleware)
      .concat(errorHandlerMiddleware),
});

export default store;
