import {
  AT_WORK,
  SM,
  CHECK_INTERVAL,
  CHECK_POS_INTERVAL,
  PROFILE_ACTIVATED,
  RESET_ORDERS,
  CHANGE_SOCKET_CONNECT,
  DRIVER_OFFLINE,
  ERROR_MSG,
  CLEAR_ERROR,
  ORDER_REQUEST_BODY,
  PUSH_ORDER_ACCEPTED,
  PUSH_ORDER_ACCEPTED_ERROR,
} from './orders.actions.js'

const initialState = {
  showmenu: false,
  workStatus: JSON.parse(localStorage.getItem('isOnline')) || false,
  selectedCarStatus: false,
  selectedCarId: '',
  interval: '',
  posInterval: '',
  profileActivated: true,
  socketConnect: false,
  driverOffline: '',
  errorStatus: false,
  errorMsg: '',
  orderShowRequestBody: null,
  pushOrderAccepted: null,
  pushOrderAcceptedError: null,
}

function orders(state = initialState, action) {
  switch (action.type) {
    case AT_WORK:
      return { ...state, workStatus: action.payload }
    case SM:
      return { ...state, showmenu: action.payload }
    case CHECK_INTERVAL:
      return { ...state, interval: action.payload }
    case CHECK_POS_INTERVAL:
      return { ...state, posInterval: action.payload }
    case PROFILE_ACTIVATED:
      return { ...state, profileActivated: false }
    case RESET_ORDERS:
      return { ...state, profileActivated: true }
    case CHANGE_SOCKET_CONNECT:
      return { ...state, socketConnect: action.payload }
    case DRIVER_OFFLINE:
      return { ...state, driverOffline: action.payload, workStatus: false }
    case ERROR_MSG:
      return { ...state, errorMsg: action.payload, errorStatus: true }
    case CLEAR_ERROR:
      return { ...state, errorMsg: '', errorStatus: false }
    case ORDER_REQUEST_BODY:
      return { ...state, orderShowRequestBody: action.payload }
    case PUSH_ORDER_ACCEPTED:
      return { ...state, pushOrderAccepted: action.payload }
    case PUSH_ORDER_ACCEPTED_ERROR:
      return { ...state, pushOrderAcceptedError: action.payload }
    default:
      return { ...state }
  }
}
export default orders
