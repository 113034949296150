import { axiosInstanceWithToken } from "src/axios.config";
import { featuresApi } from "src/config-url";

export const checkAllowFeaturesBackEnd = async (featureUrl: string) => {
  try {
    const data = await axiosInstanceWithToken.get(`${featuresApi}${featureUrl}`);
    return data;
  } catch (error) {
    console.log("error in checkAllowGrayLog: ", error);
    return null;
  }
};
