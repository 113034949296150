import { LANGUAGE_NAME } from "src/shared/const/localstorage";
import {
  GET_CARDS_LOADING,
  BUTTON_ACTIVE,
  BUTTON_DISABLED,
  GET_CARDS_SUCCESS,
  GET_CARDS_ERROR,
  CLEAR_CARDS,
} from "./ccard.action";

const initialState = {
  userCardsList: [],
  isButtonActive: false,
  addCardData: {},
  isLoading: false,
};

const cardReducerNew = (state = initialState, action) => {
  switch (action.type) {
    case BUTTON_DISABLED: {
      return {
        ...state,
        isButtonActive: false,
        addCardData: {},
      };
    }
    case BUTTON_ACTIVE: {
      const languageName = localStorage.getItem(LANGUAGE_NAME);
      const addCardData = action.payload.metadata || action.payload;

      return {
        ...state,
        isButtonActive: true,
        addCardData: {
          ...addCardData,
          language: languageName,
          gatewayType: action.payload.gatewayType || addCardData.gatewayType,
          cardId: action.payload.cardId,
        },
      };
    }
    case GET_CARDS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case GET_CARDS_SUCCESS: {
      return {
        ...state,
        userCardsList: {
          ...action.payload.data,
          totalCards: action.payload.total,
        },
        isLoading: false,
      };
    }
    case GET_CARDS_ERROR: {
      return {
        ...state,
        userCardsList: [],
        isLoading: false,
      };
    }

    case CLEAR_CARDS:
      return { ...state, userCardsList: [] };

    default:
      return {
        ...state,
      };
  }
};

export default cardReducerNew;
