import axios from "axios";
import { osmMapPolyline } from "../../../config";

const fetchRouteData = async ({ routeUrl }) => {
  try {
    const response = await axios.get(routeUrl);
    const routeCoordinates = response.data.features[0].geometry.coordinates;

    const formattedCoordinates = routeCoordinates.map((coord) => [
      coord[1],
      coord[0],
    ]);
    return { formattedCoordinates, response };
  } catch (error) {
    throw new Error("Error fetchRouteData", error);
  }
};

export const updateRoute = async ({
  startPos,
  endPos,
  errorCallBack = () => null,
}) => {
  let routeUrl = `${osmMapPolyline}?start=${startPos}&end=${endPos}`;
  try {
    const routeData = await fetchRouteData({ routeUrl });
    return routeData;
  } catch (error) {
    errorCallBack();
    throw new Error("Error fetchRouteData", error);
  }
};
