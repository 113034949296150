import { extractReputationLevel } from "../helpers/extractReputationLevel";
import {
  initialDriverCommissionState,
  initialDriverRatingState,
  initialReputationRulesState,
} from "../mocks";
import {
  FETCH_DRIVER_RATING_REQUEST,
  FETCH_DRIVER_RATING_SUCCESS,
  FETCH_DRIVER_RATING_FAILURE,
  FETCH_REPUTATION_RULES_SUCCESS,
  FETCH_DRIVER_COMMISSION_SUCCESS,
  GET_DRIVER_CURRENT_LEVEL,
  GET_DRIVER_CURRENT_MOTIVATION_BENEFITS,
  FETCH_CONFIGURATION_SUCCESS,
  FETCH_CURRENT_COMMISSION_SUCCESS,
} from "./actions/motivation/motivation.actions";

const initialState = {
  isMotivationLoading: false,
  driverRatingData: initialDriverRatingState,
  driverCommissionData: initialDriverCommissionState,
  reputationRulesData: initialReputationRulesState,
  error: null,
  currentLevel: 0,
  currentCommision: 0,
  configuration: null,
  currentCommission: 0,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DRIVER_RATING_REQUEST:
      return {
        ...state,
        isMotivationLoading: true,
        error: null,
      };
    case FETCH_DRIVER_RATING_SUCCESS:
      return {
        ...state,
        driverRatingData: action.payload,
        error: null,
      };
    case FETCH_DRIVER_COMMISSION_SUCCESS: {
      const sortedData = action.payload.slice().sort((a, b) => a.amountOfOrders - b.amountOfOrders);

      return {
        ...state,
        driverCommissionData: sortedData,
        error: null,
      };
    }
    case FETCH_REPUTATION_RULES_SUCCESS: {
      const sortedData = action.payload
        .slice()
        .sort((a, b) => extractReputationLevel(a.reputationType) - extractReputationLevel(b.reputationType));

      return {
        ...state,
        isMotivationLoading: false,
        reputationRulesData: sortedData,
        error: null,
      };
    }
    case FETCH_DRIVER_RATING_FAILURE:
      return {
        ...state,
        isMotivationLoading: false,
        error: action.payload,
      };
    case GET_DRIVER_CURRENT_LEVEL:
      return {
        ...state,
        currentLevel: action.payload,
      };
    case GET_DRIVER_CURRENT_MOTIVATION_BENEFITS:
      return {
        ...state,
        currentMotivationBenefits: action.payload,
      };
    case FETCH_CONFIGURATION_SUCCESS:
      return {
        ...state,
        configuration: action.payload,
        isMotivationLoading: false,
        error: null,
      };
    case FETCH_CURRENT_COMMISSION_SUCCESS:
      return {
        ...state,
        currentCommission: action.payload,
        error: null,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
