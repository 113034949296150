import {
	SEND_MONEY,
	GET_MONEY,
	CLEAR_M,
	CONVERT_BONUSES,
	ERROR_MSG,
	CLEAR_ERROR,
	BONUS_ERROR,
	SET_NEW_CARD,
	REJECT_TRANSACTION,
} from "./money.actions";

const initialState = {
	setMStatus: false,
	getMStatus: false,
	bonusStatus: false,
	bonusError: false,
	errorStatus: false,
	rejectTransactionMoney: false,
	errorMsg: "",
	newCard: "",
};

const money = (state = initialState, action) => {
	switch (action.type) {
		case SEND_MONEY:
			return { ...state, setMStatus: true };
		case GET_MONEY:
			return { ...state, getMStatus: true };
		case REJECT_TRANSACTION:
			return { ...state, rejectTransactionMoney: true };
		case SET_NEW_CARD:
			return { ...state, newCard: action.payload };
		case CLEAR_M:
			return {
				...state,
				getMStatus: false,
				setMStatus: false,
				rejectTransactionMoney: false,
				bonusStatus: false,
				bonusError: false,
			};
		case CONVERT_BONUSES:
			return { ...state, bonusStatus: true };
		case ERROR_MSG:
			return { ...state, errorMsg: action.payload, errorStatus: true };
		case CLEAR_ERROR:
			return { ...state, errorMsg: "", errorStatus: false };
		case BONUS_ERROR:
			return { ...state, bonusError: true };
		default:
			return { ...state };
	}
};

export default money;
