import { GET_CITY_LIST, GET_ZENDESK_TOKEN, GET_ZENDESK_TOKEN_LOADING } from './profile.actions'

const initialState = {
  cityList: [],
  zendeskToken: '',
  zendeskTokenLoading: false,
}

function ProfileReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CITY_LIST:
      return { ...state, cityList: action.payload }
    case GET_ZENDESK_TOKEN:
      return { ...state, zendeskToken: action.payload }
    case GET_ZENDESK_TOKEN_LOADING:
      return { ...state, zendeskTokenLoading: action.payload }
    default:
      return { ...state }
  }
}
export default ProfileReducer
