interface IProps {
  name: string;
  value: boolean;
}

export const setFeatureLocalStore = (feature: IProps) => {
  const { name, value } = feature;
  const backEndFeaturesString = localStorage.getItem("backEndFeatures");
  const backEndFeatures = backEndFeaturesString ? JSON.parse(backEndFeaturesString) : {};
  const updatedBackEnd = { ...backEndFeatures, [name]: value };
  localStorage.setItem("backEndFeatures", JSON.stringify(updatedBackEnd));
};
