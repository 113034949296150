export const SHOW_MENU = 'SHOW_MENU'
export const MOVE_EXIT = 'MOVE_EXIT'

export const showMenu = (showM) => {
  return dispatch => {
    dispatch({
      type: SHOW_MENU,
      payload: showM
    })
  }
}

export const move = () => {
  return dispatch => {
    dispatch({
      type: MOVE_EXIT,
      payload: true
    })
  }
}

