export const SHOW_MENU_BOTTOM = 'SHOW_MENU_BOTTOM'

export const showMenuBottom = (showM) => {
  return dispatch => {
    dispatch({
      type: SHOW_MENU_BOTTOM,
      payload: showM
    })
  }
}

