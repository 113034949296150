import maplibregl from '!maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { distanceBetweenPoints } from 'src/shared/helpers/distanceBetweenPoints'

const RIGHT_ANGLE = Math.PI / 2



function closestPointOnSegment(segmentStart, segmentEnd, point) {
  // full line size start and end point
  const segmentDirection = [
    segmentEnd[0] - segmentStart[0],
    segmentEnd[1] - segmentStart[1],
  ]

  // line size my point and start
  const vectorToSegmentStart = [
    point[0] - segmentStart[0],
    point[1] - segmentStart[1],
  ]

  // (full line size + my point size) / full line size[0] ** 2 + my point size[1] **2
  const projectionFactor =
    (vectorToSegmentStart[0] * segmentDirection[0] +
      vectorToSegmentStart[1] * segmentDirection[1]) /
    (segmentDirection[0] ** 2 + segmentDirection[1] ** 2)

  if (projectionFactor <= 0) {
    return segmentStart
  } else if (projectionFactor >= 1) {
    return segmentEnd
  } else {
    return [
      segmentStart[0] + segmentDirection[0] * projectionFactor,
      segmentStart[1] + segmentDirection[1] * projectionFactor,
    ]
  }
}

export function findSegment(arrayOfCoords, myPoint) {
  let closestDistance = Infinity
  let closestSegment = []

  for (let i = 0; i < arrayOfCoords.length - 1; i++) {
    const segmentStart = arrayOfCoords[i]
    const segmentEnd = arrayOfCoords[i + 1]
    const closestPoint = closestPointOnSegment(
      segmentStart,
      segmentEnd,
      myPoint
    )
    const distance = distanceBetweenPoints(myPoint, closestPoint)

    if (distance < closestDistance) {
      closestDistance = distance
      closestSegment = [segmentStart, segmentEnd]
    }
  }

  return {
    segment: closestSegment,
    distanceInMeters: closestDistance,
  }
}

function calculateDistanceInMeters(point1, point2) {
  const earthRadius = 6371000 // Earth's radius in meters
  const lat1 = point1[0]
  const lon1 = point1[1]
  const lat2 = point2[0]
  const lon2 = point2[1]

  const dLat = (lat2 - lat1) * (Math.PI / 180)
  const dLon = (lon2 - lon1) * (Math.PI / 180)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const distance = earthRadius * c

  return distance
}

export function findNearestPoint(arrayOfCoords, myPoint) {
  let nearestPoint = null
  let nearestDistance = Infinity

  for (const coords of arrayOfCoords) {
    const distance = calculateDistanceInMeters(myPoint, coords)

    if (distance < nearestDistance) {
      nearestDistance = distance
      nearestPoint = coords
    }
  }

  return {
    point: nearestPoint,
    distanceInMeters: nearestDistance,
  }
}

//START calculation point between coords

export function calculateTriangle(traanglePoints) {

  const A = new maplibregl.LngLat(traanglePoints[0][0], traanglePoints[0][1])
  const B = new maplibregl.LngLat(traanglePoints[1][0], traanglePoints[1][1])
  const C = new maplibregl.LngLat(traanglePoints[2][0], traanglePoints[2][1])

  const AB = A.distanceTo(B)
  const AC = A.distanceTo(C)
  const BC = B.distanceTo(C)

  const sides = [AB, BC, AC]

  //we need only 2 angles
  const BAC = Math.acos((AB ** 2 + AC ** 2 - BC ** 2) / (2 * AC * AB))
  const ABC = Math.acos((AB ** 2 + BC ** 2 - AC ** 2) / (2 * AB * BC))

  //angles in radians
  const angles = [BAC, ABC]

  return [sides, angles]
}

export const legByNearAngle = (hypo, angleRad) => {
  return hypo * Math.cos(angleRad)
}

export const legByOtherAngle = (hypo, angleRad) => {
  return hypo * Math.sin(angleRad)
}

export const coordsByLeg = (coordsA, coordsB, side, leg) => {
  const latDif = coordsB[1] - coordsA[1]
  const lonDif = coordsB[0] - coordsA[0]
  const k = leg / side
  let newLat = coordsA[1] + latDif * k
  let newLon = coordsA[0] + lonDif * k
  return [newLon, newLat]
}

export const findNearsetPoinInRoute = (routeCoords, targetPoint) => {
  const distances = findDistances(routeCoords, targetPoint)
  const angles = findTriangleAngles(
    distances.distancesBetweenPoints,
    distances.distancesToTarget
  )
  const usefullTrianglesPositions = fingUsefullTrianglesPosition(angles)
  const heights = calculateTrianglesHeight(
    distances.distancesToTarget,
    angles,
    usefullTrianglesPositions
  )
  const minHeightData = compareTriangleHeights(
    heights,
    distances.minDistanceToTarget
  )

  if (minHeightData.minHeightPosition === -1) {
    return {
      coords: routeCoords[distances.minDistanceToTargetPosition],
      distance: distances.minDistanceToTarget,
    }
  } else {
    const nearestTrianglePositionA =
      usefullTrianglesPositions[minHeightData.minHeightPosition]
    const nearestTrianglePositionB = nearestTrianglePositionA + 1
    const nearestTriangleAB =
      distances.distancesBetweenPoints[nearestTrianglePositionA]
    const nearestTriangleAC =
      distances.distancesToTarget[nearestTrianglePositionA]
    const nearestTriangleAngleA = angles[nearestTrianglePositionA][0]
    return {
      coords: calculateCoordinatesOfNearestPoint(
        routeCoords[nearestTrianglePositionA],
        routeCoords[nearestTrianglePositionB],
        nearestTriangleAngleA,
        nearestTriangleAB,
        nearestTriangleAC
      ),
      nearsPoints: [
        routeCoords[nearestTrianglePositionA],
        routeCoords[nearestTrianglePositionB],
      ],
      distance: minHeightData.minHeight,
    }
  }
}

function findDistances(routeCoords, targetPoint) {
  const targetLngLat = new maplibregl.LngLat(targetPoint[0], targetPoint[1])
  let previousLonLat = new maplibregl.LngLat(
    routeCoords[0][0],
    routeCoords[0][1]
  )

  //TODO: it's fixed values for the route and not needed to be calculated every time
  let distancesBetweenPoints = []
  let distancesToTarget = []

  distancesToTarget[0] = targetLngLat.distanceTo(previousLonLat)
  let minDistanceToTarget = distancesToTarget[0]
  let minDistanceToTargetPosition = 0
  for (let i = 1; i < routeCoords.length; i++) {
    const nextLonLat = new maplibregl.LngLat(
      routeCoords[i][0],
      routeCoords[i][1]
    )
    distancesToTarget[i] = targetLngLat.distanceTo(nextLonLat)
    distancesBetweenPoints[i - 1] = previousLonLat.distanceTo(nextLonLat)
    if (distancesToTarget[i] < minDistanceToTarget) {
      minDistanceToTarget = distancesToTarget[i]
      minDistanceToTargetPosition = i
    }
    previousLonLat = nextLonLat
  }
  return {
    distancesBetweenPoints: distancesBetweenPoints,
    distancesToTarget: distancesToTarget,
    minDistanceToTarget: minDistanceToTarget,
    minDistanceToTargetPosition: minDistanceToTargetPosition,
  }
}

function findTriangleAngles(distancesBetweenPoints, distancesToTarget) {
  let angles = []
  distancesBetweenPoints.forEach((ab, i) => {
    const ac = distancesToTarget[i]
    const bc = distancesToTarget[i + 1]
    //we need only 2 angles
    const BAC = Math.acos((ab ** 2 + ac ** 2 - bc ** 2) / (2 * ac * ab))
    const ABC = Math.acos((ab ** 2 + bc ** 2 - ac ** 2) / (2 * ab * bc))
    angles[i] = [BAC, ABC]
  })
  return angles
}

function fingUsefullTrianglesPosition(angles) {
  let positions = []
  angles.forEach((angles2, i) => {
    if (angles2[0] <= RIGHT_ANGLE && angles2[1] <= RIGHT_ANGLE) {
      positions.push(i)
    }
  })
  return positions
}

function calculateTrianglesHeight(
  distancesToTarget,
  angles,
  usefullTrianglesPositions
) {
  let heights = []
  usefullTrianglesPositions.forEach((pos, i) => {
    heights[i] = distancesToTarget[pos] * Math.sin(angles[pos][0])
  })
  return heights
}

function compareTriangleHeights(heights, minDistanceToTarget) {
  let minHeight = minDistanceToTarget
  let minHeightPosition = -1
  heights.forEach((h, i) => {
    if (h < minHeight) {
      minHeight = h
      minHeightPosition = i
    }
  })
  return {
    minHeight: minHeight,
    minHeightPosition: minHeightPosition,
  }
}

function calculateCoordinatesOfNearestPoint(aCoords, bCoords, aAngle, ab, ac) {
  const ad = ac * Math.cos(aAngle)

  const latDif = bCoords[1] - aCoords[1]
  const lonDif = bCoords[0] - aCoords[0]
  const k = ad / ab
  const newLat = aCoords[1] + latDif * k
  const newLon = aCoords[0] + lonDif * k
  return [newLon, newLat]
}

