export const SHOW_MODAL_ACTION = 'SHOW_MODAL_ACTION'

export const showModalAction = status => {
  return dispatch => {
    dispatch({
      type: SHOW_MODAL_ACTION,
      payload: status,
    })
  }
}
