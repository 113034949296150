import { axiosInstance } from '../../axios.config'
import { orderApi } from '../../config'
import { apinew } from '../../config'

export const GET_CITY_LIST = 'GET_CITY_LIST'
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT'

export const GET_ZENDESK_TOKEN_LOADING = 'GET_ZENDESK_TOKEN_LOADING'
export const GET_ZENDESK_TOKEN = 'GET_ZENDESK_TOKEN'
export const getZendeskToken = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_ZENDESK_TOKEN_LOADING,
        payload: true,
      })

      const accessToken = localStorage.getItem('accessToken')

      const zendeskToken = await axiosInstance.get(apinew + '/profile/zendesktoken', {
        headers: { Authorization: `Bearer ${accessToken}` },
      })

      dispatch({
        type: GET_ZENDESK_TOKEN,
        payload: zendeskToken.data.token,
      })
    } catch (error) {
      dispatch({
        type: GET_ZENDESK_TOKEN,
        payload: '',
      })
      console.log('Error getZendeskToken:', error)
    } finally {
      dispatch({
        type: GET_ZENDESK_TOKEN_LOADING,
        payload: false,
      })
    }
  }
}

export const getCityList = () => {
  return async (dispatch) => {
    try {
      const cityListResult = await axiosInstance.get(orderApi + '/catalog/cities-list')
      dispatch({
        type: GET_CITY_LIST,
        payload: cityListResult.data.data,
      })
    } catch (e) {
      dispatch({
        type: GET_CITY_LIST,
        payload: [],
      })
    }
  }
}

export const removeAccount = () => {
  const accessToken = localStorage.getItem('accessToken')

  return async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        apinew + '/profile/deleterequest',
        {},
        { headers: { Authorization: `Bearer ${accessToken}` } },
      )

      dispatch({
        type: REMOVE_ACCOUNT,
        payload: response.data,
      })

      return response
    } catch (error) {
      console.log('Action* - Deleting Account ERROR: ', error)
      throw error
    }
  }
}
