export const SHOW_MODAL_ALERT = "SHOW_MODAL_ALERT";

export const showModalAlert = (status) => {
	return (dispatch) => {
		dispatch({
			type: SHOW_MODAL_ALERT,
			payload: status,
		});
	};
};
