import styles from "./styles.module.scss";
import { classNames } from "src/shared/lib/classNames/classNames";

export const Skeleton = (props) => {
  const { className, height, width, border } = props;

  const mockStyles = {
    width,
    height,
    borderRadius: border,
  };

  return <div className={classNames(styles.Skeleton, {}, [className])} style={mockStyles} />;
};

export default Skeleton;
