import {
  GET_S_TRANSACTIONS,
  ERROR_MSG,
  CLEAR_ERROR,
    } from './stransactions.actions.js'
     
    const initialState = {
      sTransactionsList: [],
      errorStatus: false,
      errorMsg: "",
    }
    
    function stransactions(state = initialState, action) {
      switch (action.type) { 
        case GET_S_TRANSACTIONS:
          return { ...state, sTransactionsList: action.payload, }
        case ERROR_MSG:
          return {...state, errorMsg: action.payload, errorStatus: true }
        case CLEAR_ERROR:
          return {...state, errorMsg: "", errorStatus: false }
        default:
          return { ...state }
      }
    }
    export default stransactions
    