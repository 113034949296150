import { updateRoute } from "../../../components/CustomMap/helpers/updateRoute";

export const getRoutePolyline = async ({
  state,
  orderDestinationPoints,
  setState,
  polylineErrorHandler = () => null,
}) => {
  const coordsDriver = JSON.parse(localStorage.getItem("coords"));
  const passedPoints = localStorage.getItem("passedPoints");

  let passedRouteStore = localStorage.getItem("passedRoute")
    ? JSON.parse(localStorage.getItem("passedRoute"))
    : null;

  let passedRouteStoreLast = null;

  if (passedRouteStore) {
    // get last poly point from passed route
    passedRouteStoreLast = passedRouteStore[passedRouteStore.length - 1];
  }

  const editedCoordsDriver = {
    lat: coordsDriver.latitude,
    lon: coordsDriver.longitude,
  };

  let orderPoints;

  // get order points by order stage
  const destinationPoints = (() => {
    if (!orderDestinationPoints) {
      return null;
    }

    if (state.stage === 4 || state.stage === 1) {
      return [orderDestinationPoints[0]];
    }

    return orderDestinationPoints;
  })();

  if (destinationPoints) {
    if (passedPoints) {
      const parsedPassedPoints = JSON.parse(passedPoints);

      // filter passed points
      const resultArray = destinationPoints.filter(
        (item1) =>
          !parsedPassedPoints.some(
            (item2) => item1.lat === item2[0] && item1.lon === item2[1]
          )
      );

      orderPoints = [editedCoordsDriver, ...resultArray];
    } else {
      orderPoints = [editedCoordsDriver, ...destinationPoints];
    }

    if (passedRouteStoreLast) {
      // get last poly point from passed route
      const lastPassedPoint = {
        lat: passedRouteStoreLast[0],
        lon: passedRouteStoreLast[1],
      };

      // connect last passed poly point and passed points
      orderPoints = [lastPassedPoint, ...orderPoints];
    }

    if (orderPoints) {
      // request for get new polyline
      let diverPosErr = false;

      const positions = await Promise.all(
        orderPoints.flatMap(async (point, index) => {
          const prevItem = orderPoints[index - 1];

          if (!prevItem) {
            return [[point.lat, point.lon]];
          }

          try {
            const { formattedCoordinates } = await updateRoute({
              startPos: [prevItem.lon, prevItem.lat],
              endPos: [point.lon, point.lat],
            });

            return formattedCoordinates;
          } catch {
            polylineErrorHandler();
            diverPosErr = true;
            return [];
          }
        })
      );

      // connect all new polyline points arrays to one
      let positionsToOneArr = positions.reduce((accumulator, currentArray) => {
        return accumulator.concat(currentArray);
      }, []);

      // added oly new point from server
      const newPolyPoints = positionsToOneArr;

      // if diverPosErr we return only built route
      if (diverPosErr) {
        const driverPosInx = positionsToOneArr.findIndex(
          (item) =>
            JSON.stringify(item) !==
            JSON.stringify([editedCoordsDriver.lat, editedCoordsDriver.lon])
        );

        positionsToOneArr = newPolyPoints.slice(driverPosInx + 1);
      }

      // connect passed poly points to new poly
      if (passedRouteStore && !diverPosErr) {
        positionsToOneArr = [...passedRouteStore, ...positionsToOneArr];
      }

      setState({
        polylinePositions: positionsToOneArr,
        zoomFullRoute: state.zoomOnClientOn && !diverPosErr,
        zoomOnClientOn: false,
        newPolyPoints,
        mapPoints: destinationPoints,
      });
    }
  }
};
