import { rtkApi } from "src/shared/api/rtkApi";
import { apinew } from "src/config";

export const filterByPolygonsQuery = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getPolygonsByRegionCode: builder.query({
      query: ({ regionId }) => ({
        url: `${apinew}/regions/${regionId}/districts`,
        method: "GET",
      }),
    }),
    getDailyAttempt: builder.query({
      query: () => ({
        url: `${apinew}/profile/daily-attempt`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetPolygonsByRegionCodeQuery, useGetDailyAttemptQuery } = filterByPolygonsQuery;
