import { t } from "i18next";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { showModalConfirm } from "./modalConfirm.actions";

import "./modalconfirm.scss";
import { Portal } from "src/shared/ui/Portal/Portal";

class ModalConfirm extends Component {
	Confirm = () => {
		if (this.props.isCustomFromApp) {
			this.props.customConfirmHandler();
		} else {
			let status = !this.props.showMConfirm;
			this.props.showModalConfirm(status);
			this.props.confirmHandler();
		}
		if (this.props.additionalHandler) {
			this.props.additionalHandler();
		}
	};

	Cancel = () => {
		if (this.props.onDefaultCancel) {
			return this.props.onDefaultCancel();
		}
		if (this.props.isCustomFromApp) {
			this.props.customCancelHandler();
		} else {
			let status = !this.props.showMConfirm;
			this.props.showModalConfirm(status);
		}

		if (this.props.isCustomCancel) {
			this.props.cancelModalConfirm();
		}
	};

	render() {
		return (
			<Portal>
				<div className="modalconfirm">
					<div className="modalconfirm-section">
						<p className="confirm-text">{this.props.mainConfirmText}</p>
						<div className="confirm-buttons">
							{this.props.isCustomCancel || this.props.isCustomFromApp ? (
								<button onClick={this.Confirm} style={{ width: "105px" }}>
									{t("settings")}
								</button>
							) : (
								<button onClick={this.Confirm}>{t("yes")}</button>
							)}

							{this.props.isCustomCancel || this.props.isCustomFromApp ? (
								<button onClick={this.Cancel} style={{ width: "105px" }}>
									{t("cancellation")}
								</button>
							) : (
								<button onClick={this.Cancel}>{t("no")}</button>
							)}
						</div>
					</div>
				</div>
			</Portal>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		showMConfirm: state.modalConfirm.showMConfirm,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showModalConfirm: (status) => dispatch(showModalConfirm(status)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirm);
