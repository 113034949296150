import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { metersToKm } from "src/services/metersToKm";

export const useOrderDistance = (orderDistance) => {
  const [distance, setDistance] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (orderDistance > 1000) {
      const km = metersToKm(orderDistance, {
        calculateLowerNum: 10,
        calculateHigherNum: 10,
      });
      setDistance(`${km} ${t("km")}`);
    } else {
      setDistance(`${orderDistance.toFixed(0)} ${t("m")}`);
    }
  }, [orderDistance, t]);

  return distance;
};
