import React, { useEffect, useRef } from "react";

const useDrawPolygons = ({ map, disabledDrawPolygons, drawPolygonProps, mapLoaded }) => {
  const {
    polygons = [],
    selectedPolygons = [],
    onPolygonClick = ({ event, polygon }) => null,
  } = drawPolygonProps || {};

  const defaultPolygonColor = "#088";
  const activeDefaultPolygonColor = "#0F0";

  // create polygons
  useEffect(() => {
    if (map.current && mapLoaded && !disabledDrawPolygons && polygons && polygons.length > 0) {
      polygons.forEach((polygon) => {
        
        const sourceName = polygon.name;
        if (!map.current.getSource(sourceName)) {
          map.current.addSource(sourceName, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [polygon.coords],
              },
            },
          });
          map.current.addLayer({
            id: sourceName,
            type: "fill",
            source: sourceName,
            layout: {},
            paint: {
              "fill-color": polygon.color || defaultPolygonColor,
              "fill-opacity": 0.8,
            },
          });

          const clickListener = (event) => {
            onPolygonClick({ event, polygon });
          };
          // Add click event listener
          map.current.on("click", sourceName, clickListener);
        }
      });
    }
  }, [disabledDrawPolygons, map, mapLoaded, onPolygonClick, polygons, defaultPolygonColor]);

  // polygons actions: change polygons colors
  useEffect(() => {
    if (map.current && !disabledDrawPolygons && polygons && mapLoaded) {
      polygons.forEach((polygon) => {
        const polygonColor = selectedPolygons.includes(polygon.name)
          ? activeDefaultPolygonColor
          : defaultPolygonColor;
        map.current.setPaintProperty(polygon.name, "fill-color", polygonColor);
      });
    }
  }, [map, disabledDrawPolygons, polygons, selectedPolygons, mapLoaded]);
};

export default useDrawPolygons;
