import React from "react";
import { t } from "i18next";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Button } from "src/shared/ui/Button/button";
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right.svg";
import { classNames } from "src/shared/lib/classNames/classNames";
import "./ErrorPage.scss";

const ErrorPage = ({ errorInfo }) => {
	const reloadPage = () => {
		window.location.reload();
	};

	return (
		<div className="ErrorPageWrapper">
			<div className="header">
				<h1>{t("oops")}</h1>
				<h4>{t("something_went_wrong")}</h4>
				<div className="accordionError">
					<Accordion
						style={{ borderRadius: "16px", maxWidth: "80%", margin: "0 auto" }}
					>
						<AccordionSummary
							expandIcon={<ArrowRightIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography>{t("error_details")}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>{errorInfo}</Typography>
						</AccordionDetails>
					</Accordion>
				</div>
			</div>
			<div className="error-btn--block">
				<Button className={classNames("back_btn", {}, [])} onClick={reloadPage}>
					{t("back")}
				</Button>
			</div>
		</div>
	);
};

export default ErrorPage;
