import { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useCountdown from "src/hooks/useCountdown";
import { ORDER_CANCELLATION_TIMEOUT_MS } from "src/shared/constants";
import { Button, ButtonVariant } from "src/shared/ui/Button/button";
import { formatMillisecondsAsTime } from "../../helpers/formatMillisecondsAsTime";
import { getRemainingCancellationTimeInSec } from "../../helpers/getRemainingCancellationTimeInSec";
import useCancelOrderTimer from "../../hooks/useTimeToCancelOrder";
import classNames from "classnames";
import styles from "./styles.module.scss";

const CancelOrderButton = (props) => {
  const { onClick } = props;

  const [durationAnimation, setDurationAnimation] = useState(ORDER_CANCELLATION_TIMEOUT_MS);

  const { isCancelOrderAllowed } = useCancelOrderTimer();
  const { count } = useCountdown({ countStart: getRemainingCancellationTimeInSec() });

  const { t } = useTranslation();

  useEffect(() => {
    if (getRemainingCancellationTimeInSec()) {
      setDurationAnimation(getRemainingCancellationTimeInSec());
    }
  }, []);

  const [textToCancelOrder, isButtonDisabled] = useMemo(() => {
    if (count > 0) {
      const formattedTime = formatMillisecondsAsTime(count * 1000);
      return [t("cancellation_availability_notice", { time: formattedTime }), true];
    }

    return [t("cancel_order"), !isCancelOrderAllowed];
  }, [t, count, isCancelOrderAllowed]);

  return (
    <Button
      className={classNames(styles.cancelOrderButton, { [styles.canCancel]: !isButtonDisabled })}
      disabled={isButtonDisabled}
      onClick={onClick}
      style={{ "--animation-duration": `${durationAnimation}s` }}
      variant={ButtonVariant.order}
    >
      <span className={styles.buttonText}>{textToCancelOrder}</span>
    </Button>
  );
};

export default memo(CancelOrderButton);
