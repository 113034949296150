import React from "react";

import styles from "./styles.module.scss";

export const BlackButton = ({ children, className, ...otherProps }) => {
  const { disabled } = otherProps;
  return (
    <button
      {...otherProps}
      className={`${styles.blackButton} ${className} ${disabled ? styles.blackButtonDisabled : ""}`}
    >
      {children}
    </button>
  );
};
