import {
  GET_INSPECTION,
  NO_INSPECTION,
  CLEAR,
  ADD_NEW_TO_STATUS,
  CLEAR_ADD_NEW_TO_STATUS,
  ERROR_MSG,
  CLEAR_ERROR,
  BTN_ACTIVE,
  } from './cardocs.actions.js'
   
  const initialState = {
    lastInspectionStatus: false,
    inspectionInfo: {},
    addNewTOStatus: false,
    goBack: false,
    errorStatus: false,
    errorMsg: "",
    btnActive: true,
  }
  
  function carInspections(state = initialState, action) {
    switch (action.type) { 
      case GET_INSPECTION: 
        return {...state, inspectionInfo: action.payload, lastInspectionStatus: true, }  
      case NO_INSPECTION:
        return {...state, lastInspectionStatus: false}
      case CLEAR: 
        return {...state, lastInspectionStatus: false, inspectionInfo: {}, goBack: false }
      case ADD_NEW_TO_STATUS: 
        return {...state, addNewTOStatus: true }
      case CLEAR_ADD_NEW_TO_STATUS: 
        return {...state, addNewTOStatus: false, goBack: true, btnActive: true }
      case ERROR_MSG:
        return {...state, errorMsg: action.payload, errorStatus: true }
      case CLEAR_ERROR:
        return {...state, errorMsg: "", errorStatus: false }
      case BTN_ACTIVE:
        return {...state, btnActive: action.payload }
      default:
        return { ...state }
    }
  }
  export default carInspections
  