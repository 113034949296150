import { TimeManager } from "src/services/timer/TimeManager";
import { CANCEL_ORDER_LAST_ACTIVE_TIME } from "src/shared/const/localstorage";
import { ORDER_CANCELLATION_TIMEOUT_MS } from "src/shared/constants";

export const getRemainingCancellationTimeInSec = () => {
  const lastActiveTime = TimeManager.getLastActiveTime(CANCEL_ORDER_LAST_ACTIVE_TIME);
  const remainingTimeInMs = TimeManager.getRemainingTimeInMs(lastActiveTime, ORDER_CANCELLATION_TIMEOUT_MS);
  const remainingTimeInSeconds = Math.floor(remainingTimeInMs / 1000);

  if (remainingTimeInSeconds > 0) {
    return remainingTimeInSeconds;
  }

  return 0;
};
