/**
 * @deprecated
 * Use https://www.npmjs.com/package/classnames instead.
 */

export function classNames(cls, mods = {}, additional = []) {
  return [
    cls,
    ...additional.filter(Boolean),
    ...Object.entries(mods)
      .filter(([_, value]) => Boolean(value))
      .map(([className]) => className),
  ].join(" ");
}
