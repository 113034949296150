import React, { Component } from "react";
import { classNames } from "../../lib/classNames/classNames";
import { Input } from "../Input/inputs";
import { t } from "i18next";

import { ReactComponent as BackIcon } from "../../../images/close.svg";
import { ReactComponent as SearchIcon } from "../../../images/search.svg";
import { ReactComponent as CheckIcon } from "../../../images/check.svg";

import { Button } from "../Buttons/DefautlButton/button";
import { withTranslation } from "react-i18next";
import "./select.scss";
import { LANGUAGE_NAME } from "src/shared/const/localstorage";

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      selectedItem: null,
      selectedLanguage: "uk",
    };
  }

  componentDidMount() {
    const language = localStorage.getItem(LANGUAGE_NAME);
    if (language && language !== "undefined") {
      const findCurrentLanguage = this.props.listItem.find((lang) => lang?.locale === language);

      this.setState({
        selectedLanguage: language,
        selectedItem: findCurrentLanguage,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedItem } = this.state;

    if (prevState.selectedItem !== selectedItem) {
      if (selectedItem?.locale) {
        this.setState({ selectedLanguage: selectedItem?.locale });
        localStorage.setItem(LANGUAGE_NAME, selectedItem?.locale);
      }
    }
  }

  handleSearchQueryChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleListItemClick = (item) => {
    if (this.props.onSelectOptionId) {
      this.props.onSelectOptionId(!item.id ? item : item.id);
    }

    if (this.props.onSetItem) {
      this.props.onSetItem(item);
    }

    this.setState({ selectedItem: item });
  };

  check = (currentItem, list) => {
    if (list?.id) {
      return currentItem === list.id;
    }
    return currentItem === list;
  };

  render() {
    const {
      className,
      listItem,
      searchBlock = false,
      placeholder,
      toggleSelect,
      title,
      currentId,
      topCustomComponent = null,
      suffixText = " ",
    } = this.props;
    const { searchQuery, selectedItem } = this.state;

    const filteredList = listItem.filter((item) => {
      if (t(item.name)) {
        return t(item.name).toLowerCase().includes(searchQuery.toLowerCase());
      } else {
        return listItem;
      }
    });
    return (
      <div className={classNames("Wrapper", {}, [className])}>
        {topCustomComponent && topCustomComponent}

        <div className="block_title">
          <div className="back_icon" onClick={toggleSelect}>
            <BackIcon className="arrowleft_icon" />
          </div>

          <div className="title_text">{title}</div>
        </div>

        {searchBlock ? (
          <div className="block-search">
            <div className="search_input-block">
              <Input
                className={classNames("search_input", {}, [])}
                onChange={this.handleSearchQueryChange}
                placeholder={placeholder}
              />
            </div>
            <div className="search_icon-block">
              <SearchIcon className="icon_search" />
            </div>
          </div>
        ) : null}

        <div className="block-select_menu">
          <ul className="nav-menu">
            {filteredList.map((list, idx) => {
              return (
                <li key={idx} onClick={() => this.handleListItemClick(list)}>
                  <div>
                    {t(list.name ? list.name : list)}
                    <span>{suffixText}</span>
                  </div>
                  {this.check(currentId, list) && <CheckIcon className="check_icon" />}
                </li>
              );
            })}
          </ul>
        </div>

        <div className={classNames("block_btn", { btn_disable: currentId }, [])}>
          <Button onClick={toggleSelect}>{t("confirm")}</Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Select);
