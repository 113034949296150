import { SHOW_MODAL_ACTION } from './modalAction.actions.js'

const initialState = {
  showMAction: false,
}

function modalAction(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL_ACTION:
      return { ...state, showMAction: action.payload }
    default:
      return { ...state }
  }
}
export default modalAction
