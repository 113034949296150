import React from "react";

import styles from "./styles.module.scss";

export const BorderedButton = ({ children, className, ...otherProps }) => {
  return (
    <button {...otherProps} className={`${styles.borderedButton} ${className}`}>
      {children}
    </button>
  );
};
