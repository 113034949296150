import {
  GET_G_TRANSACTIONS,
  ERROR_MSG,
  CLEAR_ERROR,
    } from './gtransactions.actions.js'
     
    const initialState = {
        gTransactionsList: [],
        errorStatus: false,
        errorMsg: "",
    }
    
    function gtransactions(state = initialState, action) {
      switch (action.type) { 
        case GET_G_TRANSACTIONS:
          return { ...state, gTransactionsList: action.payload, }
        case ERROR_MSG:
          return {...state, errorMsg: action.payload, errorStatus: true }
        case CLEAR_ERROR:
          return {...state, errorMsg: "", errorStatus: false }
        default:
          return { ...state }
      }
    }
    export default gtransactions
    