import React, { useEffect } from "react";
import { checkAllowFeaturesBackEnd } from "src/shared/api/checkAllowFeaturesBackEnd";
import { checkRemoteLoggerDelay } from "src/shared/constants";
import { setFeatureLocalStore } from "src/shared/helpers/backEndFeatureLocalStore";

const useCheckBackEndFeatures = () => {
  // check if available logger
  useEffect(() => {
    const checkRemoteLogger = async () => {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        return;
      }

      const data = await checkAllowFeaturesBackEnd("/remoteLogger");
      if (data) {
        const remoteLogger = data?.data?.enabled || false;
        setFeatureLocalStore({ name: "remoteLogger", value: remoteLogger });
      } else {
        setFeatureLocalStore({ name: "remoteLogger", value: false });
      }
    };
    checkRemoteLogger();
    const checkBackedFeaturesInterval = setInterval(checkRemoteLogger, checkRemoteLoggerDelay);

    return () => clearInterval(checkBackedFeaturesInterval);
  }, []);
};

export default useCheckBackEndFeatures;
