import React, { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import { ReactComponent as BackIcon } from "src/images/back.svg";
import { classNames } from "src/shared/lib/classNames/classNames";
import styles from "./styles.module.scss";

function PageHeader({
  headerTitle,
  className,
  headerClassName,
  headerContent,
  onHeaderBackClick,
  headerBackPage,
  HeaderIcon,
  onHeaderIconClick = () => null,
  shouldShowBackIcon = true,
  headerAlignment = "top",
}) {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    if (onHeaderBackClick) {
      onHeaderBackClick();
    } else {
      headerBackPage ? navigate(headerBackPage) : navigate(-1);
    }
  }, [headerBackPage, navigate, onHeaderBackClick]);

  return (
    <div className={classNames(styles.wrapper, {}, [className])}>
      <MobileTopBarHeight />
      {headerContent && headerAlignment === "top" && (
        <div className={styles.additionInfo}>{headerContent}</div>
      )}

      <div className={styles.content}>
        <div className={styles.icons}>
          {shouldShowBackIcon && <BackIcon className={styles.icon} onClick={handleGoBack} />}
          {HeaderIcon && (
            <HeaderIcon
              className={classNames(styles.icon, {}, [headerClassName])}
              onClick={onHeaderIconClick}
            />
          )}
        </div>

        <p className={styles.title}>{headerTitle}</p>
        {headerContent && headerAlignment === "bottom" && (
          <div className={styles.additionInfo}>{headerContent}</div>
        )}
      </div>
    </div>
  );
}

export default memo(PageHeader);
