import { useState, useEffect, useRef, useCallback } from "react";
import { TimeManager } from "src/services/timer/TimeManager";
import { CANCEL_ORDER_LAST_ACTIVE_TIME } from "src/shared/const/localstorage";
import { ORDER_CANCELLATION_TIMEOUT_MS } from "src/shared/constants";

const useCancelOrderTimer = () => {
  const [isCancelOrderAllowed, setIsCancelOrderAllowed] = useState(false);

  const cancelOrderTimerRef = useRef(null);

  const startCancelOrderTimer = useCallback((duration) => {
    setIsCancelOrderAllowed(false);

    cancelOrderTimerRef.current = setTimeout(() => {
      setIsCancelOrderAllowed(true);
    }, duration);

    if (!localStorage.getItem(CANCEL_ORDER_LAST_ACTIVE_TIME)) {
      TimeManager.storeCurrentTime(CANCEL_ORDER_LAST_ACTIVE_TIME);
    }
  }, []);

  const setupCancelOrderTimer = useCallback(() => {
    const lastActiveTime = TimeManager.getLastActiveTime(CANCEL_ORDER_LAST_ACTIVE_TIME);
    const elapsedTime = TimeManager.getElapsedTime(lastActiveTime);
    const shouldStartNewTimer = TimeManager.shouldStartNewTimer(
      elapsedTime,
      lastActiveTime,
      ORDER_CANCELLATION_TIMEOUT_MS,
    );

    if (shouldStartNewTimer) {
      const remainingTime = TimeManager.calculateRemainingTime(
        elapsedTime,
        lastActiveTime,
        ORDER_CANCELLATION_TIMEOUT_MS,
      );
      startCancelOrderTimer(remainingTime);
    } else {
      setIsCancelOrderAllowed(true);
    }
  }, []);

  useEffect(() => {
    setupCancelOrderTimer();

    return () => {
      clearTimeout(cancelOrderTimerRef.current);
    };
  }, []);

  return { isCancelOrderAllowed };
};

export default useCancelOrderTimer;
