import { SHOW_MENU_BOTTOM } from './bottommenu.actions.js'

const initialState = {
  showmenubottom: false,
}

function bottomMenu(state = initialState, action) {
  switch (action.type) {
    case SHOW_MENU_BOTTOM:
      return { ...state, showmenubottom: action.payload }
    default:
      return { ...state }
  }
}
export default bottomMenu
