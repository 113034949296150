import moment from "moment";

export const TimeManager = {
  // Stores the current time to localStorage under the provided key
  storeCurrentTime: (key) => localStorage.setItem(key, moment().toISOString()),

  // Retrieves the last active time from localStorage for the given key
  getLastActiveTime: (key) => moment(localStorage.getItem(key)),

  // Calculates the elapsed time since the given moment
  getElapsedTime: (lastActiveTime) => moment.duration(moment().diff(lastActiveTime)),

  // Determines if a new timer should be started based on elapsed time and a timeout threshold
  shouldStartNewTimer: (elapsedTime, lastActiveTime, timeoutThreshold) => {
    return elapsedTime.asMilliseconds() < timeoutThreshold || !lastActiveTime.isValid();
  },

  // Calculates the remaining time until the timeout threshold is reached
  calculateRemainingTime: (elapsedTime, lastActiveTime, timeoutThreshold) =>
    lastActiveTime.isValid() ? timeoutThreshold - elapsedTime.asMilliseconds() : timeoutThreshold,

  // Calculates the remaining time in milliseconds
  getRemainingTimeInMs: (lastActiveTime, timeoutThreshold) => {
    const elapsedTime = TimeManager.getElapsedTime(lastActiveTime);
    const remainingTime = TimeManager.calculateRemainingTime(elapsedTime, lastActiveTime, timeoutThreshold);

    return remainingTime;
  },
};
