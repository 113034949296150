import { rtkApi } from "src/shared/api/rtkApi";
import {  orderApi } from "src/config";

export const changeDriverQuery = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    cancel: builder.query({
      query: ({ orderId }) => ({
        url: `${orderApi}/driver/${orderId}/cancel`,
        method: "POST",
      }),
    }),
  }),
});

export const { useCancelQuery } = changeDriverQuery;
