import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Coins } from "src/images/Coins.svg";
import { ReactComponent as Bolt } from "src/images/Lightning.svg";
import { ReactComponent as CreditCard } from "src/images/credit-card.svg";
import { ReactComponent as CommentDots } from "src/images/message.svg";
import noImg from "src/media/noimg.jpg";
import { getValuteSymbol } from "src/services/getValuteSymbol";
import { paymentType } from "src/shared/constants";
import Icon from "src/shared/ui/Icon/Icon";
import DestinationPointsList from "../components/ModalOrderDestinationPoints";
import { parseJsonOrString } from "src/shared/helpers/parseJsonOrString";
import HighDemandColor from "src/components/HighDemandColor";

const useOrderInfoItems = ({ order, orderDistance, styles }) => {
  const { t } = useTranslation();
  const comment = parseJsonOrString(order.comment);
  const entranceNumber = comment?.entrance || null;
  const orderNote = comment?.note ?? comment;

  const orderInfoItems = useMemo(() => {
    const prefsArr = order?.orderPreferences?.map((pref) => t(pref.name)).join(", ");

    return [
      {
        customElement: (
          <DestinationPointsList
            entranceNumber={entranceNumber}
            destinationPoints={order.destinationPoints}
            orderDistance={orderDistance}
          />
        ),
      },
      {
        icon: <img alt="" src={order?.clientPhoto ? order.clientPhoto : noImg} />,
        iconText: order?.clientFullName,
      },
      {
        icon: <Icon Svg={CommentDots} />,
        iconText: orderNote,
      },
      {
        icon: <Icon Svg={Bolt} className={styles.prefsIcon} />,
        iconText: prefsArr?.length > 0 ? t("optional_extras") : null,
        value: prefsArr?.length > 0 ? prefsArr : null,
      },
      {
        icon: <Icon Svg={CreditCard} fill />,
        iconText: t("payment"),
        value: t(paymentType[order?.paymentType]),
      },
    ];
  }, [
    entranceNumber,
    order?.clientFullName,
    order.clientPhoto,
    order.currency,
    order.destinationPoints,
    order?.highDemandColor,
    order?.orderPreferences,
    order?.paymentType,
    order?.priceEstimated,
    orderDistance,
    orderNote,
    styles.prefsIcon,
    t,
  ]);

  return orderInfoItems;
};

export default useOrderInfoItems;
