import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance } from "../../../../axios.config";
import { orderApi } from "../../../../config";

export const POST_PAID_WAITING_PENDING = "POST_PAID_WAITING_PENDING";
export const POST_PAID_WAITING_SUCCESS = "POST_PAID_WAITING_SUCCESS";

export const paidWaiting = createAsyncThunk(
  "orders/paidWaiting",
  async ({ orderId, type }, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      dispatch({ type: POST_PAID_WAITING_PENDING });
      await axiosInstance.post(
        orderApi + `/driver/${orderId}/${type}-pending`,
        {},
        { headers: { Authorization: `Bearer ${accessToken}` } },
      );
      dispatch({ type: POST_PAID_WAITING_SUCCESS });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const WAITING_TIME = "WAITING_TIME";

export const checkWaitingTime = (data) => {
  return (dispatch) => {
    dispatch({
      type: WAITING_TIME,
      payload: data,
    });
  };
};
