import Geocode from 'react-geocode'
import { apiKey } from '../../config'
Geocode.setApiKey(apiKey)
Geocode.setLanguage('ru')
Geocode.setRegion('ua')

export const SEND_POINTS = 'SEND_POINTS'
export const SEND_RADIUS = 'SEND_RADIUS'
export const SET_DRIVER_POSITION = 'SET_DRIVER_POSITION'
export const SET_IS_MAP_MOVING = 'SET_IS_MAP_MOVING'

export const setIsMapMoving = isMove => {
  return dispatch => {
    dispatch({
      type: SET_IS_MAP_MOVING,
      payload: isMove,
    })
  }
}

export const sendRadiusToMap = radius => {
  return dispatch => {
    dispatch({
      type: SEND_RADIUS,
      payload: radius,
    })
  }
}

export const sendPointsToMap = points => {
  return dispatch => {
    dispatch({
      type: SEND_POINTS,
      payload: points,
    })
  }
}

export const setDriverPosition = pos => {
  return dispatch => {
    dispatch({
      type: SET_DRIVER_POSITION,
      payload: pos,
    })
  }
}
