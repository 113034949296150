import {
  SEND_POINTS,
  SEND_RADIUS,
  SET_DRIVER_POSITION,
  SET_IS_MAP_MOVING,
} from './map.actions'

const initialState = {
  points: [],
  radius: 4, //!TODO
  isMapMoving: false,
  /*  point: [
      {
        lat: "50.46485699999999",
        lng: "30.629212"
      },
      {
        lat: "50.4701951",
        lng: "30.6254701"
      },
    ],*/
  driverPosition: {},
}

function map(state = initialState, action) {
  switch (action.type) {
    case SEND_POINTS:
      return { ...state, points: action.payload }
    case SET_DRIVER_POSITION:
      return { ...state, driverPosition: action.payload }
    case SEND_RADIUS:
      return { ...state, radius: action.payload }
    case SET_IS_MAP_MOVING:
      return { ...state, isMapMoving: action.payload }

    default:
      return { ...state }
  }
}
export default map
