// Function to calculate pixel size from meters
const calculatePixelSizeFromMeters = ({ centerCoordinates, meters, map }) => {
  const centerPixel = map.current.project(centerCoordinates);
  const northCoordinates = [centerCoordinates[0], centerCoordinates[1] + meters / 111320]; // Roughly 1 meter in latitude
  const northPixel = map.current.project(northCoordinates);
  return Math.abs(northPixel.y - centerPixel.y);
};

// Update custom element size on zoom
export const sizeInPxByMapZoom = ({ centerCoordinates, meters, map }) => {
  const pixelSize = calculatePixelSizeFromMeters({ centerCoordinates, meters, map });
  return pixelSize;
};
