import {
  SHOW_MODAL_ALERT_SC,
    } from './modalAlertSC.actions.js'
     
    const initialState = {
      showMAlert: false,
    }
    
    function modalAlertSC(state = initialState, action) {
      switch (action.type) {
        case SHOW_MODAL_ALERT_SC:
          return {...state, showMAlert: action.payload }
        default:
          return { ...state }
      }
    }
    export default modalAlertSC
    