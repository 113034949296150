import React from "react";
import { useState } from "react";

import { t } from "i18next";

import ChangeDriverButton from "./comnponents/ChangeDriverButton";
import ChangeDriverPopup from "./comnponents/ChangeDriverPopup";

const ChangeDriver = (props) => {
  const [showChangeDriverPopup, setShowChangeDriverPopup] = useState(false);
  const { onChangeDriver } = props;
  return (
    <>
      <ChangeDriverButton onClick={() => setShowChangeDriverPopup(true)}>
        {t("change_driver")}
      </ChangeDriverButton>
      <ChangeDriverPopup
        onClick={async () => {
          await onChangeDriver();
          setShowChangeDriverPopup(false);
        }}
        onClose={() => setShowChangeDriverPopup(false)}
        showChangeDriverPopup={showChangeDriverPopup}
      />
    </>
  );
};

export default ChangeDriver;
