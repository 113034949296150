import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";

import { setWebKitWorkState } from "src/shared/funcForMobile";

import { axiosInstance } from "../../axios.config";
import { apinew } from "../../config";

export const SM = "SM";
export const AT_WORK = "AT_WORK";
export const CHECK_INTERVAL = "CHECK_INTERVAL";
export const CHECK_POS_INTERVAL = "CHECK_POS_INTERVAL";
export const PROFILE_ACTIVATED = "PROFILE_ACTIVATED";
export const RESET_ORDERS = "RESET_ORDERS";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const ORDER_REQUEST_BODY = "ORDER_REQUEST_BODY";
export const PUSH_ORDER_ACCEPTED = "PUSH_ORDER_ACCEPTED";
export const PUSH_ORDER_ACCEPTED_ERROR = "PUSH_ORDER_ACCEPTED_ERROR";

export const showM = (sm) => {
  return (dispatch) => {
    dispatch({
      type: SM,
      payload: sm,
    });
  };
};

export const checkPushOrderAccepted = () => {
  return (dispatch) => {
    const pushOrderAccepted = JSON.parse(localStorage.getItem("pushOrderAccepted"));

    if (pushOrderAccepted) {
      dispatch({
        type: PUSH_ORDER_ACCEPTED,
        payload: pushOrderAccepted,
      });
    } else {
      dispatch({
        type: PUSH_ORDER_ACCEPTED,
        payload: false,
      });
    }
  };
};

export const checkPushOrderAcceptedError = () => {
  console.log("checkPushOrderAcceptedError");
  return (dispatch) => {
    const pushOrderAcceptedError = JSON.parse(localStorage.getItem("pushOrderAcceptedError"));

    if (pushOrderAcceptedError) {
      dispatch({
        type: PUSH_ORDER_ACCEPTED_ERROR,
        payload: pushOrderAcceptedError,
      });
    } else {
      dispatch({
        type: PUSH_ORDER_ACCEPTED_ERROR,
        payload: false,
      });
    }
  };
};

export const chgWorkStatus = createAsyncThunk(
  "driver/chgWorkStatus",
  async (getWorkData, { rejectWithValue, dispatch }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const url = getWorkData.status ? `${apinew}/profile/online` : `${apinew}/profile/offline`;

      const data = getWorkData.status
        ? {
            driverId: getWorkData.driverId,
            carId: getWorkData.carId,
            lat: getWorkData.lat,
            lon: getWorkData.lon,
            destinationDistricts: getWorkData.destinationDistricts,
          }
        : {};

      const response = await axiosInstance({
        method: "post",
        url,
        headers: { Authorization: `Bearer ${accessToken}` },
        data,
      });

      if (response.status === 200 || response.status === 204) {
        const endpoint = new URL(window.location.href).pathname;

        if (endpoint !== "/orders") {
          setWebKitWorkState(getWorkData.status);
        }

        localStorage.setItem("isOnline", getWorkData.status);
        dispatch({ type: AT_WORK, payload: getWorkData.status });
      }
    } catch (error) {
      if (error.response && error.response.data.Data === "driver_not_activated") {
        dispatch({ type: PROFILE_ACTIVATED });
      } else if (error.response && error.response.data.Data === "driver_in_blacklist") {
        dispatch({ type: DRIVER_OFFLINE, payload: t("account_blocked_by_administrator") });
      } else if (error.response && error.response.data.Data === "driver_blocked") {
        dispatch({ type: DRIVER_OFFLINE, payload: t("account_blocked") });
      } else if (error.response && error.response.data.Data === "balance_below_or_equal_zero") {
        dispatch({ type: DRIVER_OFFLINE, payload: t("not_enough_funds_on_balance") });
      } else {
        return rejectWithValue(error);
      }
    }
  },
);

export const checkInterval = (interval) => {
  return (dispatch) => {
    dispatch({
      type: CHECK_INTERVAL,
      payload: interval,
    });
  };
};

export const checkPosInterval = (posInterval) => {
  return (dispatch) => {
    dispatch({
      type: CHECK_POS_INTERVAL,
      payload: posInterval,
    });
  };
};

export const resetOrders = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_ORDERS,
    });
  };
};

export const CHANGE_SOCKET_CONNECT = "CHANGE_SOCKET_CONNECT";
export const changeSocketConnect = (status) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SOCKET_CONNECT,
      payload: status,
    });
  };
};

export const DRIVER_OFFLINE = "DRIVER_OFFLINE";
export const driverOffline = createAsyncThunk(
  "driver/driverOffline",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance({
        method: "post",
        url: `${apinew}/profile/offline`,
        headers: { Authorization: `Bearer ${accessToken}` },
        data: {},
      });

      if (response.status === 200 || response.status === 204) {
        dispatch({ type: DRIVER_OFFLINE, payload: data.reason });
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CHECK_IS_ONLINE = "CHECK_IS_ONLINE";
export const checkIsOnline = createAsyncThunk(
  "driver/checkIsOnline",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance({
        method: "get",
        url: `${apinew}/profile/checkonline`,
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (response.status === 200 || response.status === 204) {
        if (!response.data.data) {
          localStorage.setItem("isOnline", false);
          localStorage.removeItem("socket_initialized");
          const getWorkData = { status: false };
          await dispatch(chgWorkStatus(getWorkData));
        }
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const resetDriverOffline = () => {
  //console.log("Enter resetDriverOffline")
  return (dispatch) => {
    dispatch({
      type: DRIVER_OFFLINE,
      payload: "",
    });
  };
};

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};
