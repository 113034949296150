import {
  GET_BRANDS,
  GET_MODELS,
  GET_COLORS,
  GET_CLASSES,
  GET_PREFS,
  ERROR_MSG,
  CLEAR_ERROR,
  CIRCULAR_INFO,
} from './addcar.actions.js'

const initialState = {
  brands: [],
  models: [],
  colors: [],
  classes: [],
  preferences: [],
  errorStatus: false,
  errorMsg: '',
  currentCircleStep: 3,
  circleCircleFill: 100,
}

function addcar(state = initialState, action) {
  switch (action.type) {
    case GET_BRANDS:
      return { ...state, brands: action.payload }
    case GET_MODELS:
      return { ...state, models: action.payload }
    case GET_COLORS:
      return { ...state, colors: action.payload }
    case GET_CLASSES:
      return { ...state, classes: action.payload }
    case GET_PREFS:
      return { ...state, preferences: action.payload }
    case ERROR_MSG:
      return { ...state, errorMsg: action.payload, errorStatus: true }
    case CLEAR_ERROR:
      return { ...state, errorMsg: '', errorStatus: false }
    case CIRCULAR_INFO:
      return {
        ...state,
        currentCircleStep: action.payload.currentCircleStep,
        circleCircleFill: action.payload.circleCircleFill,
      }
    default:
      return { ...state }
  }
}
export default addcar
