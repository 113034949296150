import {
    SHOW_MODAL_ALERT,
    } from './modalAlert.actions.js'
     
    const initialState = {
      showMAlert: false,
    }
    
    function modalAlert(state = initialState, action) {
      switch (action.type) {
        case SHOW_MODAL_ALERT:
          return {...state, showMAlert: action.payload }
        default:
          return { ...state }
      }
    }
    export default modalAlert
    