export const calculateLastDigits = (card) => {
  const cardNumber = card.cardPan;

  if (!cardNumber) return;

  if (cardNumber.length <= 4) {
    return `****${card.cardPan}`;
  } else {
    return cardNumber.slice(-8);
  }
};
