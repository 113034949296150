import { useEffect } from "react";
import { useRef } from "react";

import styles from "./styles.module.scss";
import { sizeInPxByMapZoom } from "../../helpers/sizeInPxByMapZoom";

import maplibregl from "!maplibre-gl";

export const useDrawRounds = (props) => {
  const { rounds, map, mapLoaded, styleIsLoad } = props;
  const prevRoundsRef = useRef(new Map());

  useEffect(() => {
    if (mapLoaded && map.current && styleIsLoad && rounds) {
      const { radius, areas } = rounds;
      const prevRounds = prevRoundsRef.current;

      areas.forEach((area) => {
        if (prevRounds && prevRounds.has(area.id)) {
          const currentArea = prevRounds.get(area.id);

          // update color
          if (currentArea.color !== area.color) {
            const mapElement = currentArea.mapElement;
            console.log("area.color", area.color);
            const color = area.color || "transparent";
            if (mapElement) {
              mapElement._element.style.background = `radial-gradient(ellipse at center, ${color} 0%, rgba(255,255,255,0) 70%)`;
              prevRoundsRef.current.set(currentArea.id, { ...currentArea, color: area.color });
            }
          }
          return;
        }
        const centerCoordinates = [Number(area.lon), Number(area.lat)];
        const color = area.color;
        // Add a custom HTML element at the center of the circle
        const customElement = document.createElement("div");
        customElement.classList.add(styles.radialGradientCircle); // Assign the class generated by SCSS module
        customElement.style.background = `radial-gradient(ellipse at center, ${color} 0%, rgba(255,255,255,0) 70%)`;

        // add element to map
        const newRound = new maplibregl.Marker({
          element: customElement,
          rotationAlignment: "map",
          pitchAlignment: "map",
        });

        // update previous rounds
        prevRoundsRef.current.set(area.id, { ...area, mapElement: newRound });

        newRound.setLngLat(centerCoordinates).addTo(map.current);

        // Initial size update
        const setCustomElementSize = () => {
          const sizeByZoomPx = sizeInPxByMapZoom({ centerCoordinates, meters: radius, map });
          customElement.style.width = `${sizeByZoomPx}px`;
          customElement.style.height = `${sizeByZoomPx}px`;
        };

        setCustomElementSize();

        // Update size on zoom change
        map.current.on("zoom", setCustomElementSize);

        // Clean up event listener on unmount
        return () => {
          map.current.off("zoom", setCustomElementSize);
        };
      });
      // remove
      prevRounds?.forEach((prevArea, id) => {
        if (areas && areas.has(id)) {
          return;
        }
        const mapElement = prevArea.mapElement;
        if (mapElement) {
          mapElement.remove(); // Remove marker from the map
        }

        // delete item from previous rounds
        prevRoundsRef.current.delete(id);
      });
    }
  }, [map, mapLoaded, rounds, styleIsLoad]);
};
