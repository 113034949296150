export const maxPauseDistance = 100;

export const orderStages = {
  Created: 0,
  Accepted: 1,
  Rejected: 2,
  CarArrived: 4,
  ClientOnBoard: 8,
  Completed: 16,
  CarNotFound: 32,
  Paid: 128,
};
