/*export const api = 'https://identity.taxidev.pro'
export const apiKey = "AIzaSyComxUEAcX-c__nGZOrJHKQfCyKY0hbrjA"
export const apinew = 'https://mdriver.taxidev.pro'
export const orderApi = 'https://order.taxidev.pro'
export const apiCard = 'https://hqua0110251.online-vm.com:8000'
export const apiCardBeck = 'https://payment.taxidev.pro'
//export const apiCard = 'https://lt-payment.herokuapp.com'
export const apiImg = 'https://file.taxidev.pro/files'
export const apiWS = 'https://mdriverws.taxidev.pro/smart-taxi-driver'   */

export const apiCard = 'https://payment.t.s.carma.taxi'
export const apiKey = 'AIzaSyComxUEAcX-c__nGZOrJHKQfCyKY0hbrjA'

export const api = process.env.REACT_APP_BACKEND_URL + '/identity'
//export const api = 'https://api.l-t.taxi/identity'

export const apinew = process.env.REACT_APP_BACKEND_URL + '/driver'
//export const apinew = 'https://api.l-t.taxi/driver'

export const orderApi = process.env.REACT_APP_BACKEND_URL + '/order'
//export const orderApi = 'https://api.l-t.taxi/order'

export const apiCardBeck = process.env.REACT_APP_BACKEND_URL + '/payment'
//export const apiCardBeck = 'https://api.l-t.taxi/payment'

export const apiImg = process.env.REACT_APP_BACKEND_URL + '/file'
//export const apiImg = 'https://api.l-t.taxi/file'   //  /files  https://api.l-t.taxi/file/download/%7BId

//export const apiWS = process.env.REACT_APP_DEV ? 'https://mdriverws.taxidev.pro/smart-taxi-driver' : 'https://mdriver.l-t.taxi/ws/negotiate'  //'https://api.l-t.taxi/driver/ws'
export const apiWS = process.env.REACT_APP_BACKEND_URL + '/driver/ws/smart-taxi-driver'

export const apiRpc = process.env.REACT_APP_RPC

export const paymentGate = `${process.env.REACT_APP_BACKEND_URL}/payment`
// set PORT=3001 &&

export const CAR_MAXIMUM_AGE = 23

export const MAXIMUM_CARS_FOR_DRIVER = 10

export const cancelDelayForOrderRequest = 15000

export const apiCallCenter = process.env.REACT_APP_CALL_CENTER_URL

export const minArrivalDistanceMeters = 300

export const mapboxApiToken = 'pk.eyJ1Ijoidm9seWtvdiIsImEiOiJjbGpzbWtpaWEwOGI5M2Zxc3BtdGJjanAzIn0.qnXBfAMMwIy9hLn-vJ4J4g'

export const mapboxMapStyleUrl =  'mapbox://styles/volykov/cljsngikj01ag01qw17lq6obw'

export const mapboxMapStyleUrlLight = 'mapbox://styles/volykov/cljwmms1p021301o447auend4'

export const mapboxMapStyleUrlDark = 'mapbox://styles/volykov/cljwmpe8o023501pj4n2u1w3z'

export const osmMapLight = process.env.REACT_APP_OSM_MAP_LIGHT

export const osmMapPolyline = process.env.REACT_APP_OSM_MAP_POLYLINE

export const minArrivalCompletedMeters = 300

export const zendeskKey = process.env.REACT_APP_ZENDESK_KEY;

export const paymentGateV2 = `${process.env.REACT_APP_BACKEND_URL}/payment/v2`;

export const signalingServerUrl = process.env.REACT_APP_SIGNALING_SERVER_URL

export const webrtcToken = process.env.REACT_APP_WEBRTC_TOKEN

export const driverLocation = `${process.env.REACT_APP_BACKEND_URL}/driver/profile/location`

export const apiReverseGeocode = process.env.REACT_APP_GEOCODE + "/reverse-geocode";

export const gpsStatus = process.env.REACT_APP_BACKEND_URL + "/driver/profile/gps";

export const apiGeocode = process.env.REACT_APP_GEOCODE + "/search";

export const reputationApi = `${process.env.REACT_APP_BACKEND_URL}/driver/reputation`;

export const driverMotivationApi = `${process.env.REACT_APP_BACKEND_URL}/driver/motivation`;

export const orderMotivationApi = `${process.env.REACT_APP_BACKEND_URL}/order/motivation`;

export const svcApi = `${process.env.REACT_APP_BACKEND_URL}/svc/info`; 