import { useMemo } from "react";
import { t } from "i18next";
import moment from "moment";
import useCountdown from "src/hooks/useCountdown";
import { formatMillisecondsAsTime } from "../helpers/formatMillisecondsAsTime";
import { useSelector } from "react-redux";

const usePreOrderTimer = () => {

  const preOrderTime = useSelector((state) => state.order.preOrderTime);

  const targetDateTime = preOrderTime && moment(preOrderTime).isValid()
  ? moment.utc(preOrderTime).add(-5, 'minutes').valueOf() 
  : null;

const remainingTimeInMs = targetDateTime
  ? Math.max(targetDateTime - moment.utc(), 0)
  : 0;

  const countdownStart = Math.max(Math.floor(remainingTimeInMs / 1000), 1);
  const { count } = useCountdown({ countStart: countdownStart });

  const [formattedTime, isButtonDisabled] = useMemo(() => {
    if (remainingTimeInMs === 0 || count <= 0) {
      return [t("on_the_spot"), true];
    }

    const timeLeft = formatMillisecondsAsTime(count * 1000);
    return [t("on_the_spot_in", { time: timeLeft }), false];
  }, [count, remainingTimeInMs]);

  return { formattedTime, isButtonDisabled, count };
};

export default usePreOrderTimer;