import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { classNames } from "src/shared/lib/classNames/classNames";
import styles from "./styles.module.scss";

const ModalOrderButtons = ({
  missedOrder,
  driverCancelOrder,
  disableBtn,
  preOrderTime,
  handleMissedModalOrderClose,
  acceptOrder,
  setShowCancelOrderModal,
}) => {
  const { t } = useTranslation();

  if (missedOrder || driverCancelOrder) {
    return (
      <div className={styles.confirmButtons}>
        <button className={styles.synced} onClick={handleMissedModalOrderClose}>
          {t("acquainted-button")}
        </button>
      </div>
    );
  }

  return (
    <div className={styles.buttonsWrap}>
      <button
        className={classNames(styles.confirmButton, {
          [styles.disabledButton]: disableBtn,
          [styles.preOrderButton]: Boolean(preOrderTime),
        })}
        disabled={disableBtn}
        onClick={acceptOrder}
      >
        {t("accept")}
      </button>

      <button className={styles.cancelButton} onClick={() => setShowCancelOrderModal(true)}>
        {t("cancel")}
      </button>
    </div>
  );
};

export default memo(ModalOrderButtons);
