// export function pulsateCircle(map) {
//   const circleLayerId = "circle-layer";
//   const minOpacity = 0.2;
//   const maxOpacity = 0.4;
//   let animationActive = true;

//   // Функция пульсации
//   function animatePulse(timestamp) {
//     if (!animationActive) {
//       return;
//     }

//     const opacity =
//       minOpacity +
//       (maxOpacity - minOpacity) * Math.abs(Math.sin(timestamp / 500));

//     // Проверяем, готов ли стиль карты
//     if (map.current.isStyleLoaded()) {
//       map.current.setPaintProperty(circleLayerId, "fill-opacity", opacity);
//     }

//     // Продолжаем анимацию, вызывая себя снова
//     requestAnimationFrame(animatePulse);
//   }

//   // Начинаем анимацию
//   requestAnimationFrame(animatePulse);

//   // Возвращаем функцию для прерывания анимации
//   return () => {
//     animationActive = false;
//   };
// }

export function pulsateCircle(map) {
  const circleLayerId = "circle-layer-outline";
  const minOpacity = 0;
  const maxOpacity = 1;
  let animationActive = true;

  // Функция пульсации
  function animatePulse(timestamp) {
    if (!animationActive) {
      return;
    }

    const opacity =
      minOpacity +
      (maxOpacity - minOpacity) * Math.abs(Math.sin(timestamp / 500));

    // Проверяем, готов ли стиль карты
    if (map.current && map.current.isStyleLoaded()) {
      map.current.setPaintProperty(circleLayerId, "line-opacity", opacity);
    }

    // Продолжаем анимацию, вызывая себя снова
    requestAnimationFrame(animatePulse);
  }

  // Начинаем анимацию
  requestAnimationFrame(animatePulse);

  // Возвращаем функцию для прерывания анимации
  return () => {
    animationActive = false;
  };
}
