import React from "react";

import { withTranslation } from "react-i18next";
import withNavigation from "src/hoc/NavigateForClass";

import ErrorPage from "src/pages/ErrorPage";
import logToGraylog from "src/shared/helpers/logToGraylog";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: "" };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  async componentDidCatch(error, errorInfo) {
    console.log("error", error);
    console.log("errorInfo", errorInfo);

    await logToGraylog({
      message: JSON.stringify({ errorInComponent: error, errorInfo, page: this.props.location }),
    });

    this.setState({
      errorInfo: errorInfo?.componentStack || "",
    });
  }

  render() {
    const { hasError, errorInfo } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorPage errorInfo={errorInfo} />;
    }

    return children;
  }
}

export default withTranslation()(withNavigation(ErrorBoundary));
