export const SHOW_MODAL_ALERT_SC = 'SHOW_MODAL_ALERT_SC'

export const showModalAlertSC = (status) => {
  return dispatch => {
    dispatch({
      type: SHOW_MODAL_ALERT_SC,
      payload: status
    })
  }
}

