import React, { Component, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import './Timer.scss'
import { t } from 'i18next'



const createTime = (time) => {
const hours = Math.floor(time / 3600);
const minutes = Math.floor((time % 3600) / 60);
const seconds = time % 60;

return hours.toString().padStart(2, '0') + ':' +
                 minutes.toString().padStart(2, '0') + ':' +
                 seconds.toString().padStart(2, '0');
}

const Index = () => {
  const [timer, setTimer] = useState(+localStorage.getItem('paidWait'));
  useEffect(()=>{
    setTimeout(()=>{
      setTimer(timer + 1)
      localStorage.getItem('paidWait') && localStorage.setItem('paidWait',timer + 1)
      
    },1000)
  },[timer])

    return (
      <div className="timer-paid">
        <div className="timer-paid__header">
          {t('paid_waiting')}
        </div>
        <div style={{ textAlign: 'center' }}>
          {createTime(timer)}
        </div>
      </div>
    );
  }

const mapStateToProps = state => {
  return {
    waitingTime: state.timer.waitingTime
  }
}

const mapDispatchToProps = dispatch => {
  return { }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)