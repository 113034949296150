import { ERROR_MSG, CLEAR_ERROR } from './finishedorder.actions.js'

const initialState = {
  errorStatus: false,
  errorMsg: '',
}

function finishedorder(state = initialState, action) {
  switch (action.type) {
    case ERROR_MSG:
      return { ...state, errorMsg: action.payload, errorStatus: true }
    case CLEAR_ERROR:
      return { ...state, errorMsg: '', errorStatus: false }
    default:
      return { ...state }
  }
}
export default finishedorder
