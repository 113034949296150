import {
    SHOW_UPF,
    DRIVER_PHOTO,
    DRIVER_LIC_F,
  //  ADD_IMG_STATUS,
    CLEAR_ADD_IMG_STATUS,
    ERROR_MSG,
    CLEAR_ERROR,
  } from './editprofile.actions.js'
  
  const initialState = {
    driverPhoto: "",
    driverLicense: "",
    showUPF: false,
    imgInfo: {},
    addImgStatus: false,
    errorStatus: false,
    errorMsg: "",
  }
  
  function updInfo(state = initialState, action) {
    switch (action.type) { 
      case SHOW_UPF: 
    return {...state, showUPF: action.payload }
    case DRIVER_PHOTO: 
      return {...state, driverPhoto: action.payload, addImgStatus: true, }
    case DRIVER_LIC_F: 
      return {...state, driverLicense: action.payload, addImgStatus: true, }
  /*  case ADD_IMG_STATUS: 
      return {...state, addImgStatus: true, }*/
    case CLEAR_ADD_IMG_STATUS: 
      return {...state, addImgStatus: false, }
    case ERROR_MSG:
      return {...state, errorMsg: action.payload, errorStatus: true }
    case CLEAR_ERROR:
      return {...state, errorMsg: "", errorStatus: false }
    default:
      return { ...state }
    }
  }
  export default updInfo
  