export const calculateZoomBasedOnSpeed = (speed) => {
    // Define a minimum and maximum zoom level
    const minZoom = 14;
    const maxZoom = 17;
  
   // Set a scaling factor to control the sensitivity of zoom to speed
   const scalingFactor = 30; // Adjust as needed
  
   // Calculate the zoom level based on speed using inversely scaled zoom
   const zoom = maxZoom - (speed / scalingFactor);
  
   // Ensure that the calculated zoom level is within the valid range
   return Math.max(minZoom, Math.min(maxZoom, zoom));
  }