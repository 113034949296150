import { axiosInstance } from "../../../axios.config";
import { paymentGate, paymentGateV2 } from "../../../config";

export const BUTTON_ACTIVE = "BUTTON_ACTIVE";
export const BUTTON_DISABLED = "BUTTON_DISABLED";
/**
 * Get button to add card
 *
 * @returns {Function} */
export const addCardData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: BUTTON_DISABLED,
    });

    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance.post(`${paymentGateV2}/driver/cards`, data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      dispatch({
        type: BUTTON_ACTIVE,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: BUTTON_DISABLED,
      });
      console.error("Error adding card data:", error);
    }
  };
};

export const GET_CARDS_SUCCESS = "GET_CARD_SUCCESS";
export const GET_CARDS_ERROR = "GET_CARD_ERROR";
export const GET_CARDS_LOADING = "GET_CARDS_LOADING";

/**
 * Get user card
 *
 */
export const getUserCards = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_CARDS_LOADING,
      payload: true,
    });

    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axiosInstance.get(`${paymentGateV2}/driver/cards`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      dispatch({
        type: GET_CARDS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CARDS_LOADING,
        payload: false,
      });

      dispatch({
        type: GET_CARDS_ERROR,
        payload: false,
      });

      // Добавьте обработку ошибки по вашему усмотрению
      console.error("Error getting user cards:", error);
    }
  };
};

export const deleteCard = (id) => {
  return async (dispatch) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      await axiosInstance.delete(`${paymentGate}/driver/cards/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      localStorage.removeItem("selectedCardId");

      await dispatch(getUserCards());
    } catch (error) {
      // Добавьте обработку ошибки по вашему усмотрению
      console.error("Error deleting card:", error);
    }
  };
};

export const GET_CARD_BY_ID_ERROR = "GET_CARD_BY_ID_ERROR";
export const GET_CARD_BY_ID_LOADING = "GET_CARD_BY_ID_LOADING";

export const getUserCardById = (cardId) => {
  return async (dispatch) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axiosInstance.get(paymentGateV2 + `/cards/${cardId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      const isLoading = response.data.status === "Pending";

      localStorage.setItem("selectedCardId", cardId);

      if (!isLoading) {
        localStorage.removeItem("cardId");
      }

      dispatch({
        type: GET_CARD_BY_ID_LOADING,
        payload: isLoading,
      });

      return response.data.status;
    } catch (error) {
      dispatch({
        type: GET_CARD_BY_ID_ERROR,
        payload: false,
      });
    }
  };
};

export const CLEAR_CARDS = "CLEAR_CARDS";
export const clearCards = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CARDS,
    });
  };
};
