export const insertPointBetweenPoints = (props) => {
  const { nearsPoints, insertPointCoords, arrayOfPoints } = props;

  let pointIndx1;
  let pointIndx2;

  const polylineWithInsertPoint = [...arrayOfPoints];
  let insertedPointIndx = null;


  if (nearsPoints && nearsPoints.length > 0) {
    const nearsPoint1 = [...nearsPoints[0]].reverse();
    const nearsPoint2 = [...nearsPoints[1]].reverse();


    polylineWithInsertPoint.forEach((item, index) => {
      if (JSON.stringify(item) === JSON.stringify(nearsPoint1)) {
        pointIndx1 = index;
      }

      if (JSON.stringify(item) === JSON.stringify(nearsPoint2)) {
        pointIndx2 = index;
      }
    });

    let biggerIndx = pointIndx2;
    let lowerIndx = pointIndx1;

    if (pointIndx1 > pointIndx2) {
      biggerIndx = pointIndx1;
      lowerIndx = pointIndx2;
    }

    polylineWithInsertPoint.splice(lowerIndx + 1, 0, insertPointCoords);
  }

  // get insert point indx
  polylineWithInsertPoint.forEach((item, index) => {
    if (JSON.stringify(item) === JSON.stringify(insertPointCoords)) {
      insertedPointIndx = index;
    }
  });

  return { polylineWithInsertPoint, insertedPointIndx };
};
