function generateYearsArray(yearsCount) {
  const years = [];
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - yearsCount;

  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }

  return years;
}

export default generateYearsArray;
