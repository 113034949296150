import { distanceBetweenPoints } from "src/shared/helpers/distanceBetweenPoints";

export const checkPauseCoords = ({
  coordsDriver,
  driverPauseCoords,
  callBackOnBigDifference = () => null,
  maxPointsDifferenceMeters,
}) => {
  let currentDriverPauseCoords = driverPauseCoords;

  if (!currentDriverPauseCoords) {
    currentDriverPauseCoords = coordsDriver;
    localStorage.setItem("driverPauseCoords", JSON.stringify(currentDriverPauseCoords));
  }

  const editedCoordsDriver = [coordsDriver.latitude, coordsDriver.longitude];
  const editedCurrentDriverPauseCoords = [
    currentDriverPauseCoords.latitude,
    currentDriverPauseCoords.longitude,
  ];

  const distance = distanceBetweenPoints(editedCoordsDriver, editedCurrentDriverPauseCoords);

  if (distance > maxPointsDifferenceMeters) {
    callBackOnBigDifference();
  }
};
