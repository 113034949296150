import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { currentOrderInfo } from '../../pages/Order/order.actions'

const ProtectedOrderPage = (props) => {
  const { Component } = props
  const accessToken = localStorage.getItem('accessToken')
  const orderShowRequestBody = JSON.parse(
    localStorage.getItem('orderShowRequestBody'),
  )
  useEffect(() => {
    const fetchData = async () => {
      if (accessToken) {
        await props.currentOrderInfo()
      }
    }

    fetchData()
  }, [])

  if (!accessToken) {
    return <Navigate to="/" />
  }

  if (props.order?.orderId) {
    return <Component />
  }

  if (!props.order?.orderId && !orderShowRequestBody?.orderId) {
    localStorage.setItem('readyForOrder', true)
    localStorage.removeItem('orderStage')
    return <Navigate to="/orders" />
  }

  return <Component />
}

const mapStateToProps = (state) => {
  return {
    order: state.order.order,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    currentOrderInfo: (routsHistory, statusId, nextStatusId, retryOnError) =>
      dispatch(
        currentOrderInfo(routsHistory, statusId, nextStatusId, retryOnError),
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedOrderPage)
