import {
    SHOW_MENU,
    MOVE_EXIT,
    } from './menu.actions.js'
     
    const initialState = {
      showmenu: false,
      goBack: false,
    }
    
    function orders(state = initialState, action) {
      switch (action.type) {
        case SHOW_MENU:
          return {...state, showmenu: action.payload }
        case MOVE_EXIT:
          return {...state, goBack: action.payload }
        default:
          return { ...state }
      }
    }
    export default orders
    