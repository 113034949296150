import { createSlice } from "@reduxjs/toolkit";

import { ErrorTypes } from "src/shared/const/errors";

const initialState = {
  errorType: null,
  titledError: false,
  supportButtonVisible: false,
  maintenanceError: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    networkError: (state) => {
      state.errorType = ErrorTypes.NETWORK_ERROR;
      state.titledError = true;
      state.supportButtonVisible = false;
      state.maintenanceError = false;
    },
    unexpectedError: (state) => {
      state.errorType = ErrorTypes.UNEXPECTED_ERROR;
      state.titledError = true;
      state.supportButtonVisible = true;
      state.maintenanceError = false;
    },
    maintenanceError: (state) => {
      state.errorType = ErrorTypes.MAINTENANCE_ERROR;
      state.titledError = false;
      state.supportButtonVisible = false;
      state.maintenanceError = true;
    },
    clearError: () => initialState,
  },
});

export const { actions: appActions } = appSlice;
export const { reducer: appReducer } = appSlice;
