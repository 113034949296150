import React, { useEffect, useState } from "react";
import { cancelDelayForOrderRequest } from "../../config";
import { clearIncomeOrder } from "src/services/clearIncomeOrder";
import { callStopOrderRingtone } from "src/shared/funcForMobile";

const useCountdownTimer = (props) => {
	const { order, disabled, declineIncomingOrder } = props;
	const [countdown, setCountdown] = useState(null);
	const [checkOrderTimer, setCheckOrderTimer] = useState();

	const clearTimer = () => {
		declineIncomingOrder(order.orderId, 1, false);
		callStopOrderRingtone();
		clearIncomeOrder();
		clearInterval(checkOrderTimer);
	};

	const getOrderTime = () => {
		const currentDate = new Date();
		const currentTime = Math.floor(currentDate.getTime() / 1000);

		let orderTime = localStorage.getItem("orderTime")
			? JSON.parse(localStorage.getItem("orderTime"))
			: null;

		if (!orderTime) {
			orderTime = currentTime;
			localStorage.setItem("orderTime", JSON.stringify(orderTime));
		}

		const timeDifference =
			cancelDelayForOrderRequest / 1000 - (currentTime - orderTime);

		if (timeDifference <= 0) {
			return clearTimer();
		}

		if (timeDifference) {
			setCountdown(timeDifference * 1000);
		}
	};

	useEffect(() => {
		if (disabled) {
			return;
		}
		// check order time from local store on first load
		getOrderTime();

		// check order time from local store every sec
		const checkOrderInterval = setInterval(() => {
			getOrderTime();
		}, 1000);

		setCheckOrderTimer(checkOrderInterval);

		return () => {
			clearInterval(checkOrderInterval);
		};
	}, [disabled]);

	return countdown;
};

export default useCountdownTimer;
