import {
  RESET_PASS,
  NOT_REGISTERED,
  CLEAR_FORGOT,
  ERROR_MSG,
  CLEAR_ERROR,
  } from './forgot.actions.js'
   
  const initialState = {
    resetPass: false,
    notRegistered: false,
    errorStatus: false,
    errorMsg: "",
  }
  
  function forgot(state = initialState, action) {
    switch (action.type) { 
      case RESET_PASS:
        return { ...state, resetPass: action.payload, /*notRegistered: false*/ }
      case NOT_REGISTERED:
        return { ...state, notRegistered: true }
      case CLEAR_FORGOT:
        return {...state, notRegistered: false, resetPass: false}
      case ERROR_MSG:
        return {...state, errorMsg: action.payload, errorStatus: true }
      case CLEAR_ERROR:
        return {...state, errorMsg: "", errorStatus: false }
      default:
        return { ...state }
    }
  }
  export default forgot
  