import { CANCEL_ORDER_LAST_ACTIVE_TIME } from "src/shared/const/localstorage";

export const clearIncomeOrder = () => {
  localStorage.removeItem("orderStage");
  localStorage.removeItem(CANCEL_ORDER_LAST_ACTIVE_TIME);
  localStorage.removeItem("timeForOrder");
  localStorage.removeItem("lastOrderId");
  localStorage.removeItem("orderShowRequestBody");
  localStorage.removeItem("currentOrder");
  localStorage.removeItem("orderTime");
  localStorage.removeItem("orderShowRequestBody");
  localStorage.removeItem("passedRoute");
  localStorage.removeItem("passedPoints");
  localStorage.removeItem("allowArrivedCoords");
};
