import React, { memo } from "react";
import styles from "./style.module.scss";

const ModalNew = (props) => {
  const {
    title,
    image,
    bottomComponent,
    buttonConfirmText,
    buttonCancelText,
    onConfirm,
    onCancel,
    customInnerComponent,
    newModalWrapClassName,
    newModalContentClassName,
  } = props;

  return (
    <div className={`${styles.newModalWrap} ${newModalWrapClassName}`}>
      <div className={`${styles.newModalContent} ${newModalContentClassName}`}>
        {customInnerComponent ? (
          customInnerComponent
        ) : (
          <>
            <div className={styles.newModalTopWrap}>
              {image}
              <p>{title}</p>
            </div>

            <div className={styles.newModalButtons}>
              <button className={styles.newModalConfirmButton} onClick={onConfirm}>
                {buttonConfirmText}
              </button>
              <button className={styles.newModalCancelButton} onClick={onCancel}>
                {buttonCancelText}
              </button>
            </div>
          </>
        )}

        {bottomComponent}
      </div>
    </div>
  );
};

export default memo(ModalNew);
