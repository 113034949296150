import React, { memo, useEffect, useMemo, useState } from "react";

import NavigateList from "./components/NavigateList";
import { navProviders } from "src/shared/constants";

import { ReactComponent as NavButton } from "src/images/NavButton.svg";
import WeGoImg from "src/images/other-map-providers/we-go.svg";
import WazeImg from "src/images/other-map-providers/waze.svg";
import GoogleImg from "src/images/other-map-providers/google.svg";
import { ReactComponent as Close } from "src/images/close.svg";

import styles from "./styles.module.scss";
import useClickOutside from "src/hooks/useClickOutside";
import LongPressDetector from "src/hoc/LongPressDetector";

export const navigationItems = [
  {
    name: "HERE WeGO",
    link: (orderCoors, coordsDriver) => `https://share.here.com/r/${coordsDriver}/${orderCoors}`,
    imageUrl: WeGoImg,
    navProvider: navProviders.wego,
  },
  {
    name: "Waze - GPS",
    link: (orderCoors) => `https://www.waze.com/ul?ll=${orderCoors}&navigate=yes&zoom=17`,
    imageUrl: WazeImg,
    navProvider: navProviders.waze,
  },
  {
    name: "Google maps",
    link: (orderCoors, coordsDriver) =>
      `https://maps.google.com/maps?saddr=${coordsDriver}&daddr=${orderCoors}`,
    imageUrl: GoogleImg,
    navProvider: navProviders.google,
  },
];

const OtherNavigation = ({ mapPoints, onMapProviderBtn, coordsDriver }) => {
  const [defaultNavProvider, setDefaultNavProvider] = useState(null);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const convertedCoordsDriver = `${coordsDriver[1]}, ${coordsDriver[0]}`;

  const onSetItem = (provider) => {
    setDefaultNavProvider(provider);
    setIsOpenMenu(false);
  };

  const pointCoordsForNav = useMemo(() => {
    if (mapPoints) {
      const passedPoints = localStorage.getItem("passedPoints")
        ? JSON.parse(localStorage.getItem("passedPoints"))
        : [];

      const filteredMapPoints = mapPoints.filter((point) => {
        return !passedPoints.some((passedPoint) => {
          return (
            JSON.stringify(passedPoint[0]) === JSON.stringify(point.lat) &&
            JSON.stringify(passedPoint[1]) === JSON.stringify(point.lon)
          );
        });
      });

      const lastPoint = mapPoints[mapPoints.length - 1];
      return `${filteredMapPoints[0]?.lat || lastPoint?.lat},${filteredMapPoints[0]?.lon || lastPoint?.lon}`;
    }
  }, [mapPoints]);

  // on click map provider get last point for redirect and build route
  const onClickNavProvider = ({ event, item, checked }) => {
    event.preventDefault();

    if (mapPoints) {
      if (checked) {
        localStorage.setItem("defaultNavProvider", JSON.stringify(item.navProvider));
        onSetItem(item);
      }

      // redirect
      window.location.href = item.link(pointCoordsForNav, convertedCoordsDriver);
    }
  };

  const onClickNavButton = () => {
    setIsOpenMenu(!isOpenMenu);
    onMapProviderBtn();
  };

  //  if press on nav button 3 sec open menu
  const onLongPressNavButton = () => {
    onMapProviderBtn();
    setIsOpenMenu(true);
  };

  //  if press on nav button less then 3 sec - open other map provider
  const onShortPressNavButton = () => {
    if (isOpenMenu) {
      return setIsOpenMenu(false);
    }
    // redirect
    window.location.href = defaultNavProvider.link(pointCoordsForNav, convertedCoordsDriver);
  };

  useEffect(() => {
    const defaultNavProviderFromStore = localStorage.getItem("defaultNavProvider")
      ? JSON.parse(localStorage.getItem("defaultNavProvider"))
      : null;

    if (defaultNavProviderFromStore) {
      const currentNawItem = navigationItems.find((item) => item.navProvider === defaultNavProviderFromStore);
      setDefaultNavProvider(currentNawItem);
    }
  }, []);

  const menuRef = useClickOutside(() => setIsOpenMenu(false));

  return (
    <div className={styles.otherNavigationWrap} ref={menuRef}>
      <div className={`${styles.navListMainWrap} ${isOpenMenu ? styles.navListMainWrapOpen : ""}`}>
        <NavigateList
          navigationItems={navigationItems}
          onClickNavProvider={onClickNavProvider}
          onSetItem={onSetItem}
          onUncheckedDefault={() => setDefaultNavProvider(null)}
        />
      </div>

      <div className={styles.otherNavigationButton}>
        {defaultNavProvider ? (
          <LongPressDetector onLongPress={onLongPressNavButton} onShortPress={onShortPressNavButton}>
            <img className={styles.chosenNavButton} src={defaultNavProvider.imageUrl} />
          </LongPressDetector>
        ) : (
          <button className={styles.navButton} onClick={onClickNavButton}>
            {isOpenMenu ? (
              <Close className={styles.navCloseImage} />
            ) : (
              <NavButton className={styles.navImage} />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default memo(OtherNavigation);
