import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { LANGUAGE_NAME } from "src/shared/const/localstorage";

i18n.use(initReactI18next).init({
  fallbackLng: localStorage.getItem(LANGUAGE_NAME) || "EN",
  lng: localStorage.getItem(LANGUAGE_NAME) || "EN",
  resources: {
    RU: {
      translations: require("./locales/ru/translations.json"),
    },
    UK: {
      translations: require("./locales/ua/translations.json"),
    },
    EN: {
      translations: require("./locales/en/translations.json"),
    },
    DE: {
      translations: require("./locales/de/translations.json"),
    },
    SK: {
      translations: require("./locales/sk/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["RU", "UK", "DE", "EN", "SK"];

export default i18n;
