import {
    STARS_RATING,
    ERROR_MSG,
    CLEAR_ERROR,
  } from './historyorder.actions.js'
  
  const initialState = {
    rating: "",
    errorStatus: false,
    errorMsg: "",
  }
  
  function historyOrder(state = initialState, action) {
    switch (action.type) { 
      case STARS_RATING:
        return {...state, rating: action.payload }
      case ERROR_MSG:
        return {...state, errorMsg: action.payload, errorStatus: true }
      case CLEAR_ERROR:
        return {...state, errorMsg: "", errorStatus: false }
      default:
        return { ...state }
    }
  }
  export default historyOrder
  