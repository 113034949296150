import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import ErrorBoundary from "./ErrorBoundary";
import store from "../store";

const Providers = ({ children }) => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Provider store={store}>{children}</Provider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default Providers;
