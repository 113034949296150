import { waitingLS } from "./waitingLS";

export const getOrderTime = (timeDurations) => {
  const currentTimeTrip = waitingLS.getCurrentTimeTrip();
  let times = currentTimeTrip;

  const finishWork = waitingLS.getStartTimeWork();
  if (timeDurations) {
    if (finishWork) {
      const currentTimeUnix = Math.floor(Date.now() / 1000);
      times = finishWork + timeDurations - currentTimeUnix;

      const lastTime = localStorage.getItem("globalPaidWait");
      if (lastTime) {
        times += +lastTime;
      }
    }
  }
  return times;
};
