import React, { useState } from "react";

const LongPressDetector = ({ onLongPress, onShortPress, duration = 300, children }) => {
  const [pressing, setPressing] = useState(false);
  let timer;

  const handleTouchStart = () => {
    timer = setTimeout(() => {
      setPressing(true);
      onLongPress();
    }, duration);
  };

  const handleTouchEnd = (event) => {
    // prevent double call onShortPress
    event.preventDefault();
    clearTimeout(timer);
    if (pressing) {
      setPressing(false);
    } else {
      onShortPress();
    }
  };

  return (
    <div
      onMouseDown={handleTouchStart}
      onMouseLeave={handleTouchEnd}
      onMouseUp={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
      onTouchEnd={handleTouchEnd}
      onTouchStart={handleTouchStart}
    >
      {children}
    </div>
  );
};

export default LongPressDetector;
