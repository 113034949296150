import Icon from "src/shared/ui/Icon/Icon";
import { classNames } from "src/shared/lib/classNames/classNames";
import { ReactComponent as MapGreenPin } from "src/images/map-pin-green.svg";
import { ReactComponent as Road } from "src/images/Path.svg";
import styles from "./styles.module.scss";
import { t } from "i18next";

const DestinationPointsList = ({ destinationPoints, orderDistance, entranceNumber }) => {
  if (!destinationPoints || !destinationPoints.length) {
    return null;
  }

  return (
    <section className={styles.destinationPoints}>
      <div className={styles.destinationPoint}>
        <div className={styles.destinationImageWrap}>
          <Icon Svg={Road} />
        </div>
        <p>{orderDistance}</p>
      </div>

      {destinationPoints.map((item, index) => {
        const isLastItem = index === destinationPoints.length - 1;
        const isFirstItem = index === 0;
        const showEntranceNumber = isFirstItem && entranceNumber;
        const { name, mainText, secondaryText, index: itemIndex } = item;

        return (
          <div
            className={classNames(styles.destinationPoint, {
              [styles.destinationPointLast]: isLastItem,
            })}
            key={`${name}_${itemIndex}`}
          >
            {isLastItem ? (
              <div className={styles.destinationImageWrap}>
                <Icon Svg={MapGreenPin} />
              </div>
            ) : (
              <div
                className={classNames(styles.pointNumBlack, {
                  [styles.pointNumFirst]: isFirstItem,
                  [styles.pointNumLast]: isLastItem,
                })}
              >
                <span className={styles.innerPointNumBlack}>{!isFirstItem && index + 1}</span>
              </div>
            )}

            <div className={styles.addressTextBlock}>
              {mainText && (
                <p className={styles.mainText}>
                  {mainText}{" "}
                  {showEntranceNumber && (
                    <span>
                      {t("entrance")} {entranceNumber}
                    </span>
                  )}
                </p>
              )}
              {secondaryText && <p className={styles.secondaryText}>{secondaryText}</p>}
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default DestinationPointsList;
