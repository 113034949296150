import {
    LOGIN_STATUS,
    REF_SMS_STATUS,
    CLEAR_REF_SMS,
    ERROR_MSG,
    CLEAR_ERROR,
  } from './checkcode.actions'
   
  const initialState = {
    chkcode: false,  // ?
    loginStatus: "",  // false
    refSmsStatus: false,
    errorStatus: false,
    errorMsg: "",
  }
  
  function chkCode(state = initialState, action) {
    switch (action.type) { 
      case LOGIN_STATUS:
          return {...state, loginStatus: action.payload }
      case REF_SMS_STATUS:
        return {...state, refSmsStatus: true }
      case CLEAR_REF_SMS:
        return {...state, refSmsStatus: false, loginStatus: "" }   
      case ERROR_MSG:
        return {...state, errorMsg: action.payload, errorStatus: true }
      case CLEAR_ERROR:
        return {...state, errorMsg: "", errorStatus: false }
      default:
        return { ...state }
    }
  }
  export default chkCode
  