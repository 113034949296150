import { memo, useEffect, useRef, useState } from "react";
import PageHeader from "../PageHeader";
import PageLayout from "../PageLayout";
import styles from "./styles.module.scss";
import { classNames } from "src/shared/lib/classNames/classNames";

// A layout component for pages with a scrollable content area.
const ScrollableLayout = (props) => {
  const { headerOptions, bodyOptions, children } = props;

  const {
    headerContent,
    headerIcon,
    headerBackPage,
    onHeaderIconClick,
    headerTitle,
    headerAlignment,
    onHeaderBackClick,
    showHeaderBackIcon,
    headerClassName,
  } = headerOptions || {};

  const { pageLayoutClassname, bodyDisableObserver } = bodyOptions || {};

  const [inView, setInView] = useState(true);
  const observerRef = useRef(null);

  useEffect(() => {
    if (bodyDisableObserver) {
      return null;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.5 },
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [bodyDisableObserver]);

  const mods = {
    [styles.showShadow]: !inView && !bodyDisableObserver,
  };

  return (
    <div className={styles.pageContainer}>
      <PageHeader
        HeaderIcon={headerIcon}
        className={classNames("", mods, [])}
        headerAlignment={headerAlignment}
        headerBackPage={headerBackPage}
        headerClassName={headerClassName}
        headerContent={headerContent}
        headerTitle={headerTitle}
        onHeaderBackClick={onHeaderBackClick}
        onHeaderIconClick={onHeaderIconClick}
        shouldShowBackIcon={showHeaderBackIcon}
      />
      <PageLayout className={pageLayoutClassname}>
        <div ref={observerRef} />
        {children}
      </PageLayout>
    </div>
  );
};

export default memo(ScrollableLayout);
