import {
    SHOW_MODAL_CONFIRM,
    } from './modalConfirm.actions.js'
     
    const initialState = {
      showMConfirm: false,
    }
    
    function modalConfirm(state = initialState, action) {
      switch (action.type) {
        case SHOW_MODAL_CONFIRM:
          return {...state, showMConfirm: action.payload }
        default:
          return { ...state }
      }
    }
    export default modalConfirm
    