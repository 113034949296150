import React from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

// for class components, which not support hooks
const withNavigation = (Component) => {
  return function WrappedComponent(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        params={params}
      />
    )
  }
}

export default withNavigation
