export const waitingLS = {
  setTimeInFinishWork: (time) => localStorage.setItem("timeInFinishWork", time),
  getTimeInFinishWork: () => +localStorage.getItem("timeInFinishWork"),
  removeTimeInFinishWork: () => localStorage.removeItem("timeInFinishWork"),

  setStartTimeWork: (time) => localStorage.setItem("startTimeWork", time),
  getStartTimeWork: () => +localStorage.getItem("startTimeWork"),
  removeStartTimeWork: () => localStorage.removeItem("startTimeWork"),

  setCurrentTimeTrip: (time) => localStorage.setItem("currentTimeTrip", time),
  getCurrentTimeTrip: () => +localStorage.getItem("currentTimeTrip"),
  removeCurrentTimeTrip: () => localStorage.removeItem("currentTimeTrip"),
};


