import React from "react";

import { changeDriverQuery } from "../api/changeDriver";

function withRTKQuery(WrappedComponent) {
  return function WithRTKQuery(props) {
    if (props.order.orderId) {
      const [onChangeDriver] = changeDriverQuery.useLazyCancelQuery();

      return (
        <WrappedComponent
          {...props}
          onChangeDriver={async () => await onChangeDriver({ orderId: props.order.orderId })}
        />
      );
    }

    return <WrappedComponent {...props} />;
  };
}

export { withRTKQuery };
