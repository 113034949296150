import moment from "moment";

export const changeWorkStatus = async (props) => {
  const { selectedCar, atwork, regInfo, chgWorkStatus, showModalCars, showModalAlert, destinationDistricts } = props;

  const now = new Date();
  let curDate =
    now.getDate() +
    "." +
    (Number(now.getMonth()) + 1) +
    "." +
    now.getFullYear() +
    "." +
    now.getHours() +
    "." +
    now.getMinutes();

  let dateFormat = "DD-MM-YYYY | HH:mm";
  let todayDate = moment(curDate, dateFormat)._d;
  let dateTO = "";
  if (selectedCar.inspectionTill) {
    dateTO = new Date(selectedCar.inspectionTill);
  }
  if (selectedCar.id && dateTO >= todayDate && !atwork) {
    const driverCoords = JSON.parse(localStorage.getItem("coords"));

    let getWorkData = {
      status: !atwork,
      driverId: regInfo.id,
      carId: selectedCar.id,
      lat: driverCoords.latitude,
      lon: driverCoords.longitude,
      destinationDistricts
    };
    await chgWorkStatus(getWorkData);
  } else if (!selectedCar.id && !atwork) {
    showModalCars(true);
  }
  if (dateTO < todayDate && selectedCar.id && !atwork) {
    showModalAlert();
  } else if (atwork) {
    localStorage.removeItem("socket_initialized");
    let getWorkData = {
      status: !atwork,
    };
    await chgWorkStatus(getWorkData);
  }
};
