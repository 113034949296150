import { axiosInstanceWithToken } from '../../axios.config'
import { apiCardBeck } from '../../config'

export const GET_DRIVER_INVOICES = 'GET_DRIVER_INVOICES'

export const getDriverInvoices = (skip = 0, take = 50) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstanceWithToken.get(`${apiCardBeck}/v1/driver/invoices`, {
        params: { skip, take }
      })

      dispatch({
        type: GET_DRIVER_INVOICES,
        payload: response.data.data
      })
    } catch (error) {
      console.log('Error fetching driver invoices:', error)
    }
  }
}