import {
  WAITING_TIME
} from './timer.actions'

const initialState = {
  waitingTime: false
}

function timer(state = initialState, action) {
  switch (action.type) {
    case WAITING_TIME:
      return {
        ...state,
        waitingTime: action.payload
      }
    default:
      return { ...state }
  }
}

export default timer