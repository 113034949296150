import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import styles from "./styles.module.scss";
import { t } from "i18next";

const NavigateList = ({ navigationItems, onClickNavProvider, onUncheckedDefault }) => {
  const [checked, setChecked] = React.useState(
    localStorage.getItem("defaultNavProvider")
      ? !!JSON.parse(localStorage.getItem("defaultNavProvider"))
      : false,
  );

  const checkboxChange = (event) => {
    if (checked) {
      localStorage.removeItem("defaultNavProvider");
      onUncheckedDefault();
    }
    setChecked(event.target.checked);
  };

  return (
    <div className={styles.navListWrap}>
      <ul className={styles.nawList}>
        {navigationItems.map((item, index) => (
          <a
            href={typeof item.link === "string" ? item.link : ""}
            key={`${item.name}_${index}`}
            onClick={(event) =>
              onClickNavProvider({ event, item, link: item.link, navProvider: item.navProvider, checked })
            }
          >
            <span>{item.name}</span>
            <img height={32} src={item.imageUrl} width={32} />
          </a>
        ))}
      </ul>
      <div className={styles.formControlLabelWrap}>
        <FormControlLabel
          className={styles.navListCheckboxLabel}
          control={
            <Checkbox checked={checked} onChange={checkboxChange} className={styles.navListCheckbox} />
          }
          label={t("save_selection")}
        />
      </div>
    </div>
  );
};

export default NavigateList;
