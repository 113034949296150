import { memo } from "react";

import classNames from "classnames";

import { Button, ButtonVariant } from "src/shared/ui/Button/button";

import styles from "./styles.module.scss";

const ChangeDriverButton = (props) => {
  const { onClick, children } = props;

  return (
    <Button
      className={classNames(styles.cancelOrderButton, styles.canCancel)}
      onClick={onClick}
      variant={ButtonVariant.order}
    >
      <span className={styles.buttonText}>{children}</span>
    </Button>
  );
};

export default memo(ChangeDriverButton);
