import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const launcherBottomPosition = {
  orders: {
    regular: "220px",
    atwork: "170px",
  },
  order: "151px",
  default: "100px",
};

const useZendeskLauncherPosition = (options) => {
  const { atwork } = options || {};

  const location = useLocation();

  useEffect(() => {
    const adjustZendeskLauncherPosition = () => {
      const isWebWidgetPresent = document.body.querySelectorAll(':scope > [data-product="web_widget"]');
      if (!isWebWidgetPresent.length) {
        return;
      }

      const zendeskLauncher = document.body.querySelector("#launcher");
      if (!zendeskLauncher) {
        return;
      }

      const getBottomValue = () => {
        switch (location.pathname) {
          case "/orders":
            return atwork ? launcherBottomPosition.orders.atwork : launcherBottomPosition.orders.regular;
          case "/order":
            return launcherBottomPosition.order;
          default:
            return launcherBottomPosition.default;
        }
      };

      zendeskLauncher.style.transition = "bottom 0.2s ease-in-out";
      zendeskLauncher.style.bottom = getBottomValue();
    };

    adjustZendeskLauncherPosition();
  }, [atwork, location.pathname]);
};

export default useZendeskLauncherPosition;
