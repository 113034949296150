import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { updateRoute } from "src/components/CustomMap/helpers/updateRoute";

export const useTimeToPoint = (props) => {
  const { startCoordsPos, endCoordsPos, disabled = false } = props;
  const timeToPoints = useRef([]);
  const isFetchingRef = useRef(false);
  const startCoordsPosRef = useRef(startCoordsPos);
  const endCoordsPosRef = useRef(endCoordsPos);
  const [time, setTime] = useState(t("calculate_time_to-point"));

  const firstTimerTime = 10000;
  const secondTimerTime = 60000;

  // find time by 1 min, clean array
  const calculateTime = () => {
    const sum = timeToPoints.current.reduce(
      (prevValue, currentValue) => prevValue + currentValue,
      0
    );
    const min = Math.min(...timeToPoints.current);

    setTime((sum / timeToPoints.current.length + min) / 2);
    timeToPoints.current = [];
  };

  const fetchData = async () => {
    try {
      isFetchingRef.current = true;

      const { response } = await updateRoute({
        startPos: startCoordsPosRef.current,
        endPos: endCoordsPosRef.current,
      });

      const duration = response.data.features[0].properties.summary.duration;
      timeToPoints.current = [...timeToPoints.current, duration];
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      isFetchingRef.current = false;
    }
  };

  // set time on first load
  useEffect(() => {
    if (typeof time === "number") {
      return;
    }
    const getTime = async () => {
      const { response } = await updateRoute({
        startPos: startCoordsPos,
        endPos: endCoordsPos,
      });

      const duration = response.data.features[0].properties.summary.duration;
      setTime(duration);
    };

    getTime();
  }, [startCoordsPos, endCoordsPos]);

  useEffect(() => {
    startCoordsPosRef.current = startCoordsPos;
    endCoordsPosRef.current = endCoordsPos;
  }, [startCoordsPos, endCoordsPos]);

  useEffect(() => {
    if (
      startCoordsPosRef.current &&
      endCoordsPosRef.current &&
      !isFetchingRef.current &&
      !disabled
    ) {
      const intervalId = setInterval(fetchData, firstTimerTime);
      const calculateTimeId = setInterval(calculateTime, secondTimerTime);

      // Clean up the interval when the component is unmounted
      return () => {
        clearInterval(intervalId);
        clearInterval(calculateTimeId);
      };
    }
  }, []);

  return time;
};
