const calculateZoomLevel = (radius) => {
  let zoomMultiplier;

  if (radius <= 4) {
    zoomMultiplier = 0.65;
  } else if (radius < 10) {
    zoomMultiplier = 0.75;
  } else {
    zoomMultiplier = 0.85;
  }

  const newZoom = 12.2 - Math.log2(radius) * zoomMultiplier;
  return newZoom;
};


export default calculateZoomLevel;
