import { isRejectedWithValue } from "@reduxjs/toolkit";

import { CLEAR_TOKEN, fetchIsServerErrorStatus } from "src/app/app.actions";
import { appActions } from "src/app/appSlice";

import { ErrorTypes } from "src/shared/const/errors";

const handleServerError = async (store) => {
  const { payload } = await store.dispatch(fetchIsServerErrorStatus());

  switch (payload) {
    case ErrorTypes.MAINTENANCE_ERROR:
      store.dispatch(appActions.maintenanceError(ErrorTypes.MAINTENANCE_ERROR));
      break;

    default:
      store.dispatch(appActions.unexpectedError(ErrorTypes.UNEXPECTED_ERROR));
  }
};

const errorHandlerMiddleware = (store) => (next) => async (action) => {
  if (isRejectedWithValue(action)) {
    const { message, response } = action.payload;

    if (message === ErrorTypes.NETWORK_ERROR) {
      store.dispatch(appActions.networkError());
      return;
    }

    if (response?.statusText === ErrorTypes.UNAUTHORIZED) {
      store.dispatch({ type: CLEAR_TOKEN, payload: false });
      return;
    }

    if (response?.status < 500) {
      return;
    }

    await handleServerError(store);
  }

  return next(action);
};

export default errorHandlerMiddleware;
