import moment from "moment";

export const formatTimeToHourMinute = (originalTime) => {
  if (!originalTime) {
    return;
  }

  const time = moment.utc(originalTime);
  return time.local().format("HH:mm");
};
