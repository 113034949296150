import { extractReputationLevel } from "src/pages/DashboardPage/helpers/extractReputationLevel";
import { mockedReputationType } from "src/pages/DashboardPage/mocks";

export const FETCH_DRIVER_RATING_REQUEST = "FETCH_DRIVER_RATING_REQUEST";

export const FETCH_DRIVER_RATING_SUCCESS = "FETCH_DRIVER_RATING_SUCCESS";
export const FETCH_DRIVER_COMMISSION_SUCCESS = "FETCH_DRIVER_COMMISSION_SUCCESS";
export const FETCH_REPUTATION_RULES_SUCCESS = "FETCH_REPUTATION_RULES_SUCCESS";
export const GET_DRIVER_CURRENT_LEVEL = "GET_DRIVER_CURRENT_LEVEL";
export const GET_DRIVER_CURRENT_MOTIVATION_BENEFITS = "GET_DRIVER_CURRENT_MOTIVATION_BENEFITS";

export const FETCH_DRIVER_RATING_FAILURE = "FETCH_DRIVER_RATING_FAILURE";
export const FETCH_CONFIGURATION_SUCCESS = "FETCH_CONFIGURATION_SUCCESS";
export const FETCH_CURRENT_COMMISSION_SUCCESS = "FETCH_CURRENT_COMMISSION_SUCCESS";

export const fetchCurrentCommissionSuccess = (data) => ({
  type: FETCH_CURRENT_COMMISSION_SUCCESS,
  payload: data,
});

export const fetchConfigurationSuccess = (data) => ({
  type: FETCH_CONFIGURATION_SUCCESS,
  payload: data,
});

export const fetchDriverRatingRequest = () => ({
  type: FETCH_DRIVER_RATING_REQUEST,
});

export const fetchDriverRatingSuccess = (data) => ({
  type: FETCH_DRIVER_RATING_SUCCESS,
  payload: data,
});

export const fetchDriverCommissionSuccess = (data) => ({
  type: FETCH_DRIVER_COMMISSION_SUCCESS,
  payload: data,
});

export const fetchReputationRulesSuccess = (data) => ({
  type: FETCH_REPUTATION_RULES_SUCCESS,
  payload: data,
});

export const fetchDriverRatingFailure = (error) => ({
  type: FETCH_DRIVER_RATING_FAILURE,
  payload: error,
});

export const getDriverCurrentLevel = () => (dispatch, getState) => {
  // const profinfo = getState().registration.profinfo;
  const driverMotivationLevel = extractReputationLevel(mockedReputationType);

  dispatch({
    type: GET_DRIVER_CURRENT_LEVEL,
    payload: driverMotivationLevel,
  });
};

export const getDriverCurrentMotivationBenefits = () => (dispatch, getState) => {
  const reputationRulesData = getState().dashboardReducer.reputationRulesData;
  // const driverProfile = getState().registration.profinfo;

  const currentMotivationLevel = extractReputationLevel(mockedReputationType);

  const currentMotivationBenefits = reputationRulesData.find(
    (item) => currentMotivationLevel === extractReputationLevel(item.reputationType),
  );

  dispatch({
    type: GET_DRIVER_CURRENT_MOTIVATION_BENEFITS,
    payload: currentMotivationBenefits,
  });
};
