import React, { useEffect } from "react";
import { calculateZoomBasedOnSpeed } from "../helpers/calculateZoomBasedOnSpeed";

const defaultMapRotation = 0;

const useMapNavigation = (props) => {
  const {
    map,
    navigation,
    navigationBearing,
    navigationCoords,
    disabledNavigation,
    zoomFullRoute,
    speed,
    defaultZoom,
    setNavigation,
  } = props;

  useEffect(() => {
    if (
      map.current &&
      navigation &&
      (navigationBearing || navigationBearing === 0) &&
      navigationCoords &&
      !disabledNavigation &&
      !zoomFullRoute
    ) {
      map.current.resize();
      const zoomBySpeed = speed
        ? calculateZoomBasedOnSpeed(speed)
        : defaultZoom;

      map.current.flyTo({
        center: navigationCoords,
        zoom: zoomBySpeed,
        bearing: navigationBearing,
      });

      // disable map rotation using right click + drag
      map.current.dragRotate.disable();

      // disable map rotation using touch rotation gesture
      map.current.touchZoomRotate.disable();

      // disable map zoom when using scroll
      map.current.scrollZoom.disable();

      // other actions
      map.current.boxZoom.disable();
      map.current.touchPitch.disable();
      map.current.doubleClickZoom.disable();
      map.current.dragPan.disable();

      return () => {
        map.current.dragRotate.enable();
        map.current.touchZoomRotate.enable();
        map.current.scrollZoom.enable();
        map.current.boxZoom.enable();
        map.current.touchPitch.enable();
        map.current.doubleClickZoom.enable();
        map.current.dragPan.enable();
        map.current.rotateTo(defaultMapRotation);
      };
    } else if (navigation) {
      setNavigation(false);
    }
  }, [
    navigation,
    navigationBearing,
    navigationCoords,
    disabledNavigation,
    zoomFullRoute,
    defaultZoom,
    speed,
  ]);
};

export default useMapNavigation;
