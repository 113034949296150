import { apinew } from "src/config";
import { dynamicPriceLayer } from "src/config-url";

import { rtkApi } from "src/shared/api/rtkApi";

interface IParams {
  classId: string;
  lat: number;
  lon: number;
}

export const pointsDynamicPrice = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getPointsDynamicPrice: builder.query({
      query: (params: IParams) => ({
        url: dynamicPriceLayer,
        method: "POST",
        body: params,
      }),
    }),
  }),
});

export const { useGetPointsDynamicPriceQuery, useLazyGetPointsDynamicPriceQuery } = pointsDynamicPrice;
