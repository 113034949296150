import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useDisableHistoryNavigation = (restrictedPaths) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pushHistoryState = () => window.history.pushState(null, "", location.pathname);

    const handleBrowserNavigation = () => {
      pushHistoryState();
      navigate(location.pathname, { replace: true });
    };

    if (restrictedPaths.includes(location.pathname) && !location.search) {
      pushHistoryState();
      window.addEventListener("popstate", handleBrowserNavigation);
    }

    return () => {
      window.removeEventListener("popstate", handleBrowserNavigation);
    };
  }, [location, navigate, restrictedPaths]);
};

export default useDisableHistoryNavigation;
