import maplibregl from '!maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'

const createMarker = ({
  map,
  markerElement,
  position,
  markerRef,
  rotation,
}) => {
  const addMarkerToMap = () => {
    const marker = new maplibregl.Marker({
      element: markerElement,
      rotationAlignment: 'map',
      pitchAlignment: 'map',
    }).setLngLat(position)

    marker.addTo(map.current)
    return marker
  }

  switch (markerRef?.current) {
    case null:
      const marker = addMarkerToMap()
      markerRef.current = marker
      break
    case undefined:
      addMarkerToMap()
      break
    default:
      markerRef.current.setLngLat(position)
      if (rotation) {
        markerRef.current.setRotation(rotation)
      }
  }
}

export default createMarker
