export const SHOW_MODAL_CONFIRM = 'SHOW_MODAL_CONFIRM'

export const showModalConfirm = (status) => {
  return dispatch => {
    dispatch({
      type: SHOW_MODAL_CONFIRM,
      payload: status
    })
  }
}

