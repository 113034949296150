import moment from "moment";

export const secondsToMinSec = (seconds) => {
  const duration = moment.duration(seconds, "seconds");
  const formattedTime = moment.utc(duration.asMilliseconds()).format("mm:ss");
  return formattedTime;
};

export const secondsToMin = (seconds) => {
  const duration = moment.duration(seconds, "seconds");
  const durationInMinutes = duration.minutes();
  return durationInMinutes;
};

export const secondsToHoursMin = (seconds) => {
  const duration = moment.duration(seconds, "seconds");
  const formattedTime = moment.utc(duration.asMilliseconds()).format("hh:mm");
  return formattedTime;
};

export const sumCurrentTimeMinutes = (minutes) => {
  const currentTimeInMs = new Date().getTime();

  // Convert timeToPoint to milliseconds
  const minutesInMs = minutes * 60 * 1000;

  // Calculate the arrival time by adding timeToPoint to the current time
  const arrivalTimeInMs = currentTimeInMs + minutesInMs;

  // Convert the arrival time to a moment object
  const arrivalMoment = moment(arrivalTimeInMs);

  // Format the arrival time in the desired format (24-hour, HH:mm)
  return arrivalMoment.format("HH:mm");
};
