import {
  GET_FINES,
  FINES_ERROR,
  CLEAR_ERROR,
  FINES_PAID_ERROR,
} from './fine.actions'

const initialState = {
  finesList: [],
  errorMsg: '',
  paidFineError: '',
}

function fine(state = initialState, action) {
  switch (action.type) {
    case GET_FINES:
      return { ...state, finesList: action.payload, errorMsg: '' }
    case FINES_ERROR:
      return { ...state, errorMsg: action.payload }
    case FINES_PAID_ERROR:
      return { ...state, paidFineError: action.payload }
    case CLEAR_ERROR:
      return { ...state, errorMsg: '', paidFineError: '' }
    default:
      return { ...state }
  }
}
export default fine
